import React from "react";
import ManagmentBanner from "../../components/managmentBanner";
import BreadCrumbComp from "../../components/breadCrumb";
import TabComponent from "../../components/tabComponent";
import "./styles.css";
import DofaTab from "./dofaTab";
import RiskTab from "./riskTab";
import OpportunityTab from "./opportunityTab";
import { getCookie } from "../../utils/getCookie";
import { useSelector } from "react-redux";
import SimpleStrategiesDofaBanner from "../../components/strategiesDofaBanner/simpleBanner";

const RiskAndOpportunityManagement = () => {
  const userData = useSelector((state) => state.userData.userData);

  const userSesion = getCookie("sesion");
  const userId = userSesion.id_usuario;

  const tabData = [
    {
      name: "DOFA",
      id: "0",
      content: <DofaTab userData={userData} userId={userId} />
    },
    {
      name: "RIESGOS",
      id: "1",
      content: <RiskTab userData={userData} userId={userId} />
    },
    {
      name: "OPORTUNIDADES",
      id: "2",
      content: <OpportunityTab userData={userData} userId={userId} />
    }
  ];

  return (
    <div>
      <BreadCrumbComp />
      <div className="searchTitle riskAndOpportunityManagement-title">
        <h1>DASHBOARD GESTIÓN DE RIESGOS Y OPORTUNIDADES</h1>
      </div>
      <TabComponent panels={tabData} />
    </div>
  );
};

export default RiskAndOpportunityManagement;
