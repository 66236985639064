import React, { useCallback, useEffect, useState } from "react";
import PillElement from "../../components/mentalMap/pillElement";
import ArrowElement from "../../components/mentalMap/arrowElement";
import HomePageCards from "../../components/homePageCards";
import MiniPill from "../../components/mentalMap/minipill";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { icons } from "../../constants";
import images from "../../constants/images";
import { useDispatch } from "react-redux";
import {
  setSelectedMapItem,
  resetSelectedMapItem,
  changeManagementHeaderBtnState,
  changeDirectorGestionBtnState
} from "../../redux/slices/navigationSlice";
import "./styles.css";
import MainLayout from "../../layouts/mainLayout";
import { setBannerData } from "../../redux/slices/utilsDataSlice";
import MediaViewer from "../../components/mediaViewer";
import { getCookie } from "../../utils/getCookie";
import { setUserData } from "../../redux/slices/userDataSlice";
import {
  callApiAsync,
  getAuthorityByUser,
  getCallJsonSettingVersion,
  postUpdateRisksAndOpportunities
} from "../../services/apiService";
import ToastNotification from "../../components/toastNotification";

const Home = () => {
  const [percentageDetails, setPercentageDetails] = useState(false);
  const userSesion = getCookie("sesion");
  const userId = userSesion.id_usuario;
  const dispatch = useDispatch();
  const [toastMessage, setToastMessage] = useState("tes");
  const [showToast, setShowToast] = useState(false);

  const openPercentageDetails = () => {
    setPercentageDetails(!percentageDetails);
  };

  const getVersion = useCallback(async () => {
    callApiAsync(getCallJsonSettingVersion, (data) => {
      dispatch(setBannerData(data));
    });
  }, [dispatch]);

  const updateRiskAndOpportunitiesByMonth = useCallback(async () => {
    callApiAsync(() => postUpdateRisksAndOpportunities({ userId: userId }));
  });

  const getAuthorityByUserFn = useCallback(async () => {
    callApiAsync(
      () => getAuthorityByUser(userId),
      (data) => {
        const userIdToSave = userId;
        const authorityList = data.authorityList;
        const pageList = data.pageList;
        const roleList = data.roleIds;
        dispatch(
          setUserData({ id: userIdToSave, authorityList, pageList, roleList })
        );
      }
    );
  });

  useEffect(() => {
    getVersion();
  }, [getVersion]);

  useEffect(() => {
    updateRiskAndOpportunitiesByMonth();
    dispatch(changeManagementHeaderBtnState(false));
    dispatch(changeDirectorGestionBtnState(false));
    getAuthorityByUserFn();
  }, []);

  dispatch(resetSelectedMapItem());

  return (
    <>
      <h1 className="map-title">SERANEST PHARMA - MAPA DE PROCESOS</h1>
      <h2 className="map-sub-title">"En búsqueda de la excelencia"</h2>
      <br />
      <div className="mentalMap container-scroll">
        <div className="VContainer">
          <div className="Hcontainer mental-map-first-column">
            <Link to="/dashboard" className="no-underline">
              <PillElement
                color={"#f0cd01"}
                text={"Contexto de la Organización"}
                iconPosition={true}
                id="lp-1"
                icon={icons.mindMap}
                iconSize={"22px"}
                onClick={() =>
                  dispatch(setSelectedMapItem("organizational-context"))
                }
              />
            </Link>
            <div className="arrow-container-l">
              <img
                className="conect-arrow-l"
                src={images.arrowLeftRDown}
                alt="arrow"
              />
            </div>
            <PillElement
              color={"#f0cd01"}
              text={"Partes Interesadas"}
              id="lp-2"
              iconSize={"23px"}
              icon={icons.group}
            />
            <div className="arrow-container-r">
              <img
                className="conect-arrow-r"
                src={images.arrowRightLDown}
                alt="arrow"
              />
            </div>
            <PillElement
              color={"#f0cd01"}
              text={"Proveedores"}
              id="lp-3"
              iconPosition={true}
              icon={icons.prooveedores}
            />
            <div className="arrow-container-l">
              <img
                className="conect-arrow-l"
                src={images.arrowLeftRDown}
                alt="arrow"
              />
            </div>
            <Link to="/clients" className="no-underline">
              <PillElement
                color={"#6a6ad8"}
                text={"Clientes"}
                id="lp-4"
                icon={icons.tieMan}
              />
            </Link>
            <div className="arrow-container-c">
              <img
                className="conect-arrow-d-II"
                src={images.arrowDownLong}
                alt="arrow"
              />
            </div>
            <div style={{ marginTop: "50px" }}>
              <PillElement
                color={"#f0cd01"}
                text={"Requerimientos"}
                id="lp-5"
                icon={icons.checkList}
              />
            </div>
            <div className="arrow-container-r-conect">
              <img
                className="conectLink-arrow-r"
                src={images.conectLink}
                alt="arrow"
              />
            </div>
          </div>
        </div>

        <div className="VContainer">
          <div className="HContainer j-center">
            <div
              className="containerBlue topMiddleLeft"
              id="strategyc-container"
            >
              <p className="map-title-container">PROCESOS ESTRATÉGICOS</p>
              <Link
                to="/management_direction/banner_admin"
                className="no-underline"
              >
                <ArrowElement
                  onClick={() => {
                    dispatch(changeManagementHeaderBtnState(false));
                    dispatch(changeDirectorGestionBtnState(true));
                  }}
                  width={"140px"}
                  height={"45px"}
                  type={"blue-arrow-II"}
                  text={"Gestión de la Dirección GDI"}
                />
              </Link>
            </div>

            <div
              className="containerBlue topMiddleRigth"
              id="control-container"
            >
              <p
                className="map-title-container"
                style={{ width: "165px", whiteSpace: "collapse" }}
              >
                PROCESOS DE MEJORAMIENTO Y CONTROL
              </p>
              <ArrowElement
                width={"140px"}
                height={"45px"}
                type={"blue-arrow-II"}
                text={"Gestión HSQ GCD"}
              />
            </div>
          </div>

          <div className="down-arrow-line">
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "350px",
                marginLeft: "-75px"
              }}
            >
              <img
                className="mind-map-down-arrow"
                src={images.arrowDown}
                alt="arrow"
              />
              <img
                className="mind-map-down-arrow"
                src={images.arrowDown}
                alt="arrow"
              />
            </div>
            <img
              style={{ marginLeft: "-115px" }}
              className="mind-map-down-arrow"
              src={images.arrowDown}
              alt="arrow"
            />
          </div>
          <div
            className="containerBlue2 middleContainer"
            id="procces-container"
            // style={{ marginTop: "20px" }}
          >
            <p className="map-title-container">procesos operacionales</p>
            <ArrowElement
              width={"580px"}
              type={"long-green-arrow"}
              textWidth={"140px"}
              text={"Gestión de Servicio al Cliente GSC"}
            />
            <div className="mind-map-downUp-arrow-group">
              <img
                className="mind-map-downUp-arrow downUp1"
                src={images.arrowDownUp}
                alt="arrow"
              />
              <img
                className="mind-map-downUp-arrow downUp2"
                src={images.arrowDownUp}
                alt="arrow"
              />
              <img
                className="mind-map-downUp-arrow downUp3"
                src={images.arrowDownUp}
                alt="arrow"
              />
              <img
                className="mind-map-downUp-arrow downUp4"
                src={images.arrowDownUp}
                alt="arrow"
              />
            </div>
            <div className="HContainer j-center" style={{ marginTop: "10px" }}>
              <div>
                <ArrowElement
                  type={"green-arrow"}
                  text={"Gestión Comercial GCO"}
                  width={"105px"}
                  height={"100px"}
                  textWidth={"60px"}
                />
              </div>
              <div>
                <Link
                  to="/marketingPromotionManagement"
                  className="no-underline"
                  state={{ menuItemId: 1 }}
                >
                  <ArrowElement
                    type={"green-arrow"}
                    text={"Gestión Mercadeo y Promoción GMP"}
                    width={"105px"}
                    height={"100px"}
                    textWidth={"80px"}
                  />
                </Link>
              </div>
              <div>
                <ArrowElement
                  type={"green-arrow-container"}
                  text={"Gestión Farmaceutica GSF"}
                  width={"270px"}
                  height={"100px"}
                  textWidth={"125px"}
                  marginItem={"0 0 0 -20px"}
                  btns={[
                    {
                      text: "Selección",
                      url: "https://www.google.com/",
                      width: "60px"
                    },
                    {
                      text: "Adquisición",
                      url: "https://www.google.com/",
                      width: "60px"
                    },
                    {
                      text: "Recepción",
                      url: "https://www.google.com/",
                      width: "60px"
                    },
                    {
                      text: "Almacenamiento",
                      url: "https://www.google.com/",
                      width: "79px"
                    },
                    {
                      text: "Distribucion",
                      url: "https://www.google.com/",
                      width: "60px"
                    }
                  ]}
                />
              </div>
              <div item xs={2} sm={2} md={2}>
                <Link
                  to="/biomedicalManagementDashboard"
                  className="no-underline"
                >
                  <ArrowElement
                    type={"green-arrow"}
                    text={"Gestión Biomédica GB"}
                    width={"105px"}
                    height={"100px"}
                    textWidth={"60px"}
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="mind-map-downUp-arrow-groupII">
            <img
              className="mind-map-downUp-arrow"
              src={images.arrowDownUp}
              alt="arrow"
            />
            <img
              className="mind-map-downUp-arrow"
              src={images.arrowDownUp}
              alt="arrow"
            />
          </div>

          <div id="process-container" style={{ marginTop: "15px" }}>
            <div className="containerBlue3 third-center-container">
              <p className="map-title-container">PROCESOS DE SOPORTE</p>
              <Grid
                container
                spacing={0}
                alignItems="center"
                style={{ marginLeft: "10px" }}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ArrowElement
                    onClick={() => {
                      dispatch(changeManagementHeaderBtnState(true));
                    }}
                    type={"blue-arrow"}
                    width={"170px"}
                    height={"50px"}
                    text={"Gestión Humana GHU"}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ArrowElement
                    type={"blue-arrow"}
                    width={"170px"}
                    height={"50px"}
                    text={"Gestión Administrativa GDA"}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ArrowElement
                    type={"blue-arrow"}
                    width={"170px"}
                    height={"50px"}
                    text={"Gestión Financiera y Contable GFC*"}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

        <div className="mental-map-third-column">
          <div className="container">
            <div className="HContainer">
              <Grid item xs={7} sm={7} md={7} style={{ width: "280px" }}>
                <div
                  className="eficacia-container"
                  style={{ width: "180px", marginBottom: "10px" }}
                  onMouseEnter={() => openPercentageDetails()}
                  onMouseLeave={() => openPercentageDetails()}
                >
                  <p className="map-title-container">EFICACIA SGC</p>
                  <div
                    style={{
                      backgroundColor: "#FBB07C",
                      color: "#FFFF",
                      height: "25px",
                      width: "90px",
                      textAlign: "center",
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto"
                    }}
                  >
                    <p className="percentage-text-mind-map">76%</p>
                  </div>
                  <div className="eficacia-total-percentage">
                    <span className="map-title-container">META:</span>
                    <span
                      style={{
                        color: "#6A6AD8",
                        fontWeight: "600",
                        fontSize: "11px"
                      }}
                    >
                      90%
                    </span>
                  </div>
                </div>
              </Grid>
              {percentageDetails && (
                <div
                  onMouseLeave={() => openPercentageDetails()}
                  className="eficacia-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100px",
                    backgroundColor: "white",
                    marginLeft: "10px"
                  }}
                >
                  <MiniPill
                    color={"#22C55E"}
                    text={"80%"}
                    iconPosition={true}
                  />
                  <MiniPill
                    color={"#FBB07C"}
                    text={"40% - 80%"}
                    iconPosition={true}
                  />
                  <MiniPill
                    color={"#E5515F"}
                    text={"40%"}
                    iconPosition={true}
                  />
                </div>
              )}
            </div>
            <img
              className="arrowRightLong"
              src={images.arrowRightLong}
              alt="arrow"
            />
            <PillElement
              color={"#f0cd01"}
              text={"Partes Interesadas"}
              id="lp-2"
              iconSize={"23px"}
              icon={icons.group}
            />
            <div className="arrow-container-c margin-third-arrow">
              <img
                className="conect-arrow-d"
                src={images.arrowDownLong}
                alt="arrow"
              />
            </div>
            <div>
              <PillElement
                color={"#6a6ad8"}
                text={"Satisfacción al Cliente"}
                iconPosition={true}
                id="lp-7"
                icon={icons.handHeart}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="HContainer j-center">
        <div className="container-riskAndOpportunityManagement HContainer j-center">
          <Link to="/riskAndOpportunityManagement" className="no-underline">
            <PillElement
              color={"#96D2F0"}
              text={"Gestión de Riesgos y Oportunidades"}
              id="lp-5"
              icon={icons.graphBar}
              iconSize={"17px"}
              width="180px"
              textColor={"#666666"}
            />
          </Link>
        </div>
      </div>
      <MainLayout>
        <h3 className="map-text">
          Siempre en la mejora continua de los procesos
        </h3>
        <h4 className="map-text-12">
          Los procesos marcados con * no se encuentran documentados en el
          sistema
        </h4>

        <HomePageCards />
      </MainLayout>
      <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </>
  );
};

export default Home;
