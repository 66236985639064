import React from "react";
import HighchartsComponent from "../../../components/highchartsComponent";
import { columnRangeChartOptions } from "../../../components/highchartsComponent/charts/columnRange";

const Total360Graph = ({ type, data, umbral }) => {
  return (
    <div>
      {/* 
      type = 0 specific
      type = 1 organizational
      */}

      {type === 0 ? (
        <HighchartsComponent
          options={columnRangeChartOptions(
            "Total 360",
            "",
            "Cantidad",
            data.total360GraphData.series,
            data.total360GraphData.categories,
            umbral
          )}
        />
      ) : (
        <HighchartsComponent
          options={columnRangeChartOptions(
            "Total 360",
            "",
            "Cantidad",
            data.total360GraphDataOrganizational.series,
            data.total360GraphDataOrganizational.categories,
            umbral
          )}
        />
      )}
    </div>
  );
};

export default Total360Graph;
