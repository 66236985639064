import React, { useEffect, useState } from "react";
import ColorIndicator from "../colorIndicator";
import SimpleSelect from "../../../components/selects/simpleSelect";
import DofaComponent from "../../../components/dofaComponent";
import { Grid } from "@mui/material";
import { months, stateDofa } from "../../../constants/selectData";
import { getCurrentMonthYear, getDateObj } from "../../../utils/dateUtils";
import { useForm } from "react-hook-form";
import { actualYearSelect } from "../../../services/dateService";
import {
  callApiAsync,
  getCallDofaByUser,
  getDofaStrategyData,
  getMacroStrategyData,
  postSaveMacroStrategy
} from "../../../services/apiService";
import { getCookie } from "../../../utils/getCookie";
import { canEditByRol } from "../authorizeFunctions";
import { useSelector } from "react-redux";
import TextArea from "../../../components/textArea";
import FormTextField from "../../../components/textField/formTextField";
import CharacterCounter from "../../../components/characterCounter";
import LaddaButton from "../../../components/buttons/laddaButton";
import ToastNotification from "../../../components/toastNotification";
import StrategiesDofaBanner from "../../../components/strategiesDofaBanner";

const DofaTab = ({ data }) => {
  const userData = useSelector((state) => state.userData.userData);
  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useForm();

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [dofa, setDofa] = useState([]);
  const [isAvailableToAdd, setIsAvailableToAdd] = useState(false);
  const [macroStrategy, setMacroStrategy] = useState({});
  const [filter, setFilter] = useState(null);
  const [strategiesData, setStrategiesData] = useState([]);

  const userSesion = getCookie("sesion");
  const processId = data.ProcessId;

  useEffect(() => {
    if (data) {
      setValue("month", data.month || getDateObj().month);
      setValue("year", data.year || getDateObj().year);
      setValue("state", 1);
      getDofaByUser(data.year, data.month, 1);
      setFilter({ year: data.year, month: data.month, state: 1 });
      getStrategiesByUser(data.year, data.month, processId);
      isEditable();
    }
  }, [data]);

  const getDofaByUser = async (year, month, state) => {
    const values = getValues();

    await callApiAsync(
      () =>
        getCallDofaByUser(
          userSesion.id_usuario,
          month,
          year,
          data.ProcessId,
          state
        ),
      (response) => {
        setDofa(response);
      }
    );
    await callApiAsync(
      () => getMacroStrategyData(values.year, data.ProcessId),
      (response) => {
        const macroStrategy = response[0];
        setMacroStrategy(macroStrategy);
        setValue("macroStrategie", macroStrategy?.description);
      }
    );
  };

  const handleChange = () => {
    isEditable();
  };

  const isEditable = () => {
    const isEditableByRol = canEditByRol(userData.roleList, data.ProcessId);
    const formData = getValues();
    const isEditableByDate = getCurrentMonthYear(formData.year, formData.month);

    if (isEditableByDate && formData.state === 1 && isEditableByRol) {
      setIsAvailableToAdd(true);
    } else {
      setIsAvailableToAdd(false);
    }

    setFilter(formData);
    getDofaByUser(formData.year, formData.month, formData.state);
  };

  const handleSaveMacro = async () => {
    const values = getValues();
    const dataToSend = {
      id: macroStrategy?.id ?? 0,
      description: values.macroStrategie,
      year: values.year,
      processId: processId
    };
    await callApiAsync(
      () => postSaveMacroStrategy(dataToSend),
      (response) => {
        setShowToast(true);
        setToastMessage("Estrategia macro guardada correctamente");
      }
    );
  };

  function getUniqueIds(data) {
    const uniqueIds = new Set();

    data.forEach((item) => {
      item.dofaMonths.forEach((dofa) => {
        uniqueIds.add(dofa.id);
      });
    });

    return Array.from(uniqueIds);
  }

  const getStrategiesByUser = async (year, month, processId) => {
    await callApiAsync(
      () => getDofaStrategyData(month, year, processId, 1),
      (response) => {
        const uniqueids = getUniqueIds(response);
        setStrategiesData(uniqueids);
      }
    );
  };

  return (
    <div>
      <ToastNotification
        severity={"success"}
        summary={toastMessage}
        detail=""
        showToast={showToast}
        setShowToast={setShowToast}
      />

      <div className="searchTitle">
        <h1 className="page-title">{data.ProcessName}</h1>
      </div>

      <StrategiesDofaBanner rowData={data} />

      <div className="HContainer j-end">
        <ColorIndicator />
        <form style={{ display: "flex", margin: "0 20px 0 0" }}>
          <SimpleSelect
            name="state"
            control={control}
            label="Estado"
            options={stateDofa}
            onChange={handleChange}
          />
          <div style={{ margin: "0 10px" }}>
            <SimpleSelect
              name="month"
              control={control}
              label="Mes"
              options={months}
              onChange={handleChange}
            />
          </div>
          <SimpleSelect
            name="year"
            control={control}
            label="Año"
            options={actualYearSelect}
            onChange={handleChange}
          />
        </form>
      </div>

      <Grid
        container
        className="dofa-general-container"
        style={{ backgroundColor: "white" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          className="strategic-container"
          style={{ backgroundColor: "white" }}
        >
          <div style={{ width: "100%" }}>
            <h1 className="page-title" style={{ textAlign: "center" }}>
              ESTRATEGIA MACRO
            </h1>
            <div style={{ margin: "0 0 0 -20px" }}>
              <div style={{ margin: "0 0 0 10px" }}>
                <CharacterCounter
                  name={"macroStrategie"}
                  control={control}
                  maxLength={500}
                />
              </div>
              {/* <div> */}
              <FormTextField
                name={"macroStrategie"}
                control={control}
                isTextArea={true}
                error={errors.macroStrategie}
                maxLength={500}
                rows={3}
                disabled={!isAvailableToAdd}
              />
            </div>
            {/* </div> */}
            {isAvailableToAdd && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px"
                }}
              >
                <LaddaButton
                  width={"200px"}
                  variant="contained"
                  onClick={() => handleSaveMacro()}
                >
                  <div className="HContainer align-center">
                    <p className="button_text">Guardar</p>
                  </div>
                </LaddaButton>
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        className="dofa-general-container"
        style={{ backgroundColor: "#d9edf7" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          className="strategic-container"
          style={{ backgroundColor: "#d9edf7" }}
        >
          <h1 className="page-title">FACTORES INTERNOS</h1>
        </Grid>

        <Grid item xs={12} md={6}>
          <DofaComponent
            data={dofa.filter((item) => item.Type === 3)}
            title="Fortalezas"
            color="#BBF7D0"
            year={filter?.year}
            month={filter?.month}
            processId={data.ProcessId}
            getDofaByUser={getDofaByUser}
            filterdata={filter}
            isAvailableToAdd={isAvailableToAdd}
            validatiosIds={strategiesData}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <DofaComponent
            data={dofa.filter((item) => item.Type === 1)}
            title="Debilidades"
            color="#FED7AA"
            year={filter?.year}
            month={filter?.month}
            processId={data.ProcessId}
            getDofaByUser={getDofaByUser}
            filterdata={filter}
            isAvailableToAdd={isAvailableToAdd}
            validatiosIds={strategiesData}
          />
        </Grid>
      </Grid>

      <Grid
        container
        className="dofa-general-container"
        style={{ backgroundColor: "#dff0d8" }}
      >
        <h1 className="page-title">FACTORES EXTERNOS</h1>
        <Grid item xs={12} md={12}></Grid>
        <Grid item xs={12} md={6}>
          <DofaComponent
            data={dofa.filter((item) => item.Type === 2)}
            title="Oportunidades"
            color="#BAE6FD"
            year={filter?.year}
            month={filter?.month}
            processId={data.ProcessId}
            getDofaByUser={getDofaByUser}
            filterdata={filter}
            isAvailableToAdd={isAvailableToAdd}
            validatiosIds={strategiesData}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DofaComponent
            data={dofa.filter((item) => item.Type === 4)}
            title="Amenazas"
            color="#FECDD3"
            year={filter?.year}
            month={filter?.month}
            processId={data.ProcessId}
            getDofaByUser={getDofaByUser}
            filterdata={filter}
            isAvailableToAdd={isAvailableToAdd}
            validatiosIds={strategiesData}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DofaTab;
