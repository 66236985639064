import { roleByProcess } from "../../constants/enums";

export const canEditByRol = (roleList, roleId) => {
  const role = roleByProcess[roleId];
  return roleList.includes(role);
};

export const getRolbyProcessId = (ProcessId) => {
  const role = roleByProcess[ProcessId];
  return role;
};
