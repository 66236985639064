import React, { useState, useRef, useEffect } from "react";
import { Skeleton } from "primereact/skeleton";
import { icons } from "../../constants";
import LaddaButton from "../buttons/laddaButton";

const ImageUploadButton = ({ onImageChange, buttonText, path, disabled }) => {
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (path) {
      fetch(path, { cache: "reload" })
        .then((response) => response.blob())
        .then((blob) => {
          const objectURL = URL.createObjectURL(blob);
          setPreview(objectURL);
        })
        .catch(() => setPreview(path));
    } else {
      setPreview(null);
    }
  }, [path]);

  const handleFileChange = (event) => {
    const file = event.target?.files?.[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
      onImageChange(file);
    }
  };

  const handleClearImage = () => {
    setPreview(null);
    onImageChange(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div>
      <label>
        {preview ? (
          <div className="mt-10">
            <img
              src={preview}
              alt="Previsualización"
              style={{
                width: "100%",
                height: "300px",
                objectFit: "contain",
                borderRadius: "10px"
              }}
              disabled
            />
          </div>
        ) : (
          <Skeleton
            className="mt-10"
            height="300px"
            width="100%"
            borderRadius="10px"
          />
        )}

        <input
          type="file"
          accept="image/*"
          hidden
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </label>
      <div className="HContainer j-center no-print">
        {!disabled && (
          <LaddaButton
            variant="contained"
            width="auto"
            fontSize="12px"
            height="40px"
            backgroundColor="#6A6AD8"
            onClick={() => fileInputRef.current.click()}
          >
            {buttonText}
          </LaddaButton>
        )}

        {preview && (
          <LaddaButton
            width="20px"
            fontSize="12px"
            height="40px"
            variant="contained"
            backgroundColor="#3f3fd9"
            onClick={handleClearImage}
            borderRadius="20px"
          >
            <img className="btn-icon-mini" src={icons.closeWhite} alt="icon" />
          </LaddaButton>
        )}
      </div>
    </div>
  );
};

export default ImageUploadButton;
