import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import React from "react";
import LaddaButton from "../../buttons/laddaButton";

const VerificationModal = ({
  open,
  handleClose,
  alertText,
  onConfirm,
  onCancel
}) => {
  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="verification-modal-title"
    >
      <DialogTitle id="verification-modal-title">
        <span className="gm-title">Confirmación</span>
      </DialogTitle>
      <DialogContent style={{ paddingTop: "10px" }}>
        <p style={{ fontSize: "14px", color: "#333", textAlign: "center" }}>
          {alertText || "¿Estás seguro de realizar esta acción?"}
        </p>
      </DialogContent>
      <DialogActions>
        <LaddaButton variant="outlined" onClick={onCancel || handleClose}>
          <div className="HContainer align-center">
            <p className="button_text">Cancelar</p>
          </div>
        </LaddaButton>
        <LaddaButton variant="contained" onClick={onConfirm}>
          <div className="HContainer align-center">
            <p className="button_text">Aceptar</p>
          </div>
        </LaddaButton>
      </DialogActions>
    </Dialog>
  );
};

export default VerificationModal;
