import React, { useEffect } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import TabComponent from "../../components/tabComponent";
import DofaTab from "./tabs/dofaTab";
import { useLocation } from "react-router-dom";
import StrategiesTab from "./tabs/strategiesTab";
import StrategiesDofaBanner from "../../components/strategiesDofaBanner";
import HeaderBanner from "../../components/headerBanner";
import { headerBannerType } from "../../constants/enums";

const Dofa = () => {
  const location = useLocation();
  const { rowData } = location.state || {};

  useEffect(() => {
    if (!rowData) {
      console.error("No se recibió rowData en el estado de la navegación.");
    }
  }, [rowData]);

  const tabData = [
    {
      name: "DOFA",
      id: "0",
      content: (
        <>
          <DofaTab data={rowData} />
        </>
      )
    },
    {
      name: "ESTRATEGIAS",
      id: "1",
      content: (
        <>
          <StrategiesTab data={rowData} />
        </>
      )
    }
  ];

  return (
    <div className="App">
      <BreadCrumbComp />
      <HeaderBanner type={headerBannerType.dofa} />
      <TabComponent panels={tabData} />
    </div>
  );
};

export default Dofa;
