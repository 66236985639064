import React from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import LaddaButton from "../../components/buttons/laddaButton";
import { icons } from "../../constants";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HighchartsComponent from "../../components/highchartsComponent";
import { horizontalColumn } from "../../components/highchartsComponent/charts/horizontalColumn";
import { pieChard } from "../../components/highchartsComponent/charts/pieChard";
import { fixedPlacementColumns } from "../../components/highchartsComponent/charts/fixedPlacementColumns";
import "./styles.css";

const seriesAGraph = [
  {
    name: "Mantenimiento a realizar en el mes",
    data: [12, 11, 23],
  },
  {
    name: "Mantenimiento hechos en el mes",
    data: [15, 13, 28],
  },
];
const categoriesAGraph = ["Nataly", "Juan", "Total"];

const burnDataChard = {
  title: "Presupuesto de Venta vs. Venta Real",
  subtitle: "",
  chard: horizontalColumn,
  yAxis: "Miles de Millones",
  series: [
    {
      name: "Suma de Presupuesto_Valores",
      data: [1.1, 1.2, 1.4, 2, 2.2, 2.4, 2.5, 2.5, 2.6, 2.7, 2.7],
    },
    {
      type: "line",
      step: "center",
      name: "Suma de Ventas Real",
      data: [1.1, 1.2, 1.4, 2, 2.2, 2.4, 2.5, 2.5, 2.6, 2.7, 2.7],
      marker: {
        lineWidth: 2,
        lineColor: "#6A6AD8",
        fillColor: "white",
      },
    },
  ],
  categories: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
};

const seriesPie = [
  {
    name: "Percentage",
    colorByPoint: true,
    data: [
      {
        name: "Equipos Importados",
        y: 15.8,
      },
      {
        name: "Equipos Instalados",
        sliced: true,
        selected: true,
        y: 56.0,
      },
      {
        name: "Equipos de Alta de Inventario",
        y: 27.2,
      },
      {
        name: "Equipos listos para instalar",
        y: 1,
      },
    ],
  },
];

const categoriesColumn = [
  "Requerimiento 1",
  "Requerimiento 2",
  "Requerimiento 3",
];

const seriesColumn = [
  {
    name: "A",
    color: "rgba(165,170,217,1)",
    data: [150, 73, 20],
    pointPadding: 0.3,
    pointPlacement: -0.2,
  },
  {
    name: "B",
    color: "rgba(126,86,134,.9)",
    data: [140, 90, 40],
    pointPadding: 0.4,
    pointPlacement: -0.2,
  },
  {
    name: "C",
    color: "rgba(248,161,63,1)",
    data: [183.6, 178.8, 198.5],

    pointPadding: 0.3,
    pointPlacement: 0.2,
  },
  {
    name: "D",
    color: "rgba(186,60,61,.9)",
    data: [203.6, 198.8, 208.5],

    pointPadding: 0.4,
    pointPlacement: 0.2,
  },
];

const BiomedicalManagementDashboard = () => {
  const navigate = useNavigate();

  return (
    <div>
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1>DASHBOARD BIOMEDICAL</h1>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <LaddaButton
            variant="contained"
            onClick={() => {
              navigate("/biomedicalManagementDashboard/manageDevice", {
                state: { type: "maintenance", data: {} },
              });
            }}
            height={"60px"}
            borderRadius={"15px"}
            width={"100%"}
          >
            <div className="HContainer align-center">
              <img
                className="btn-icon"
                src={icons.pcMonitor}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              <p className="button_text">Crear/Dar de Alta un Dispositivo</p>
            </div>
          </LaddaButton>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <LaddaButton
            variant="contained"
            color={"#404040"}
            backgroundColor={"#F4D658"}
            onClick={() => {
              navigate("/biomedicalManagementDashboard/BioMedicalRequest", {
                state: { type: "maintenance", data: {} },
              });
            }}
            width={"100%"}
            borderRadius={"15px"}
            height={"60px"}
          >
            <div className="HContainer align-center">
              <img
                className="btn-icon"
                src={icons.verificationList}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              <p className="button_text">Crear un Requerimiento</p>
            </div>
          </LaddaButton>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <LaddaButton
            variant="contained"
            onClick={() => {
              navigate("/biomedicalManagementDashboard/serviceRecords", {
                state: { type: "maintenance", data: {} },
              });
            }}
            height={"60px"}
            borderRadius={"15px"}
            width={"100%"}
          >
            <div className="HContainer align-center">
              <img
                className="btn-icon"
                src={icons.contract}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              <p className="button_text">Llenar Actas de Servicio</p>
            </div>
          </LaddaButton>
        </Grid>
      </Grid>

      {/* <div className="table-container">
        <div className="HContainer j-space-between">
          <p className="title-bio">Indicador de Mantenimiento</p>

          <LaddaButton
            variant="contained"
            color={"#404040"}
            backgroundColor={"#F4D658"}
            width={"160px"}
            height={"32px"}
            onClick={() => {
              navigate("/biomedicalManagementDashboard/indicator", {
                state: { type: "maintenance", data: {} },
              });
            }}
          >
            <div className="HContainer align-center">
              <p className="button_text">Ir a Indicador</p>
              <img
                className="btn-icon"
                src={icons.rightBlack}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
            </div>
          </LaddaButton>
        </div>
        <HighchartsComponent
          options={horizontalColumn(
            "Mantenimientos 2024",
            "",
            "Cantidad",
            seriesAGraph,
            categoriesAGraph
          )}
        />
      </div> */}

      <div className="table-container">
        <div className="HContainer j-space-between">
          <p className="title-bio">Indicador de Tiempo de Parada</p>
          <LaddaButton
            variant="contained"
            color={"#404040"}
            backgroundColor={"#F4D658"}
            width={"160px"}
            height={"32px"}
            onClick={() => {
              navigate("/biomedicalManagementDashboard/indicator", {
                state: { type: "downtime" },
              });
            }}
          >
            <div className="HContainer align-center">
              <p className="button_text">Ir a Indicador</p>
              <img
                className="btn-icon"
                src={icons.rightBlack}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
            </div>
          </LaddaButton>
        </div>
        <HighchartsComponent
          options={fixedPlacementColumns(
            "Tiempo de parada",
            "",
            "Dias desde que el equipo entre en Mantenimiento",
            seriesColumn,
            categoriesColumn
          )}
        />
      </div>

      <div className="table-container">
        <div className="HContainer j-space-between">
          <p className="title-bio">Indicador de Capacidad Instalada</p>

          <LaddaButton
            variant="contained"
            color={"#404040"}
            backgroundColor={"#F4D658"}
            width={"160px"}
            height={"32px"}
            onClick={() => {
              navigate("/biomedicalManagementDashboard/indicator", {
                state: { type: "installedCapacity" },
              });
            }}
          >
            <div className="HContainer align-center">
              <p className="button_text">Ir a Indicador</p>
              <img
                className="btn-icon"
                src={icons.rightBlack}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
            </div>
          </LaddaButton>
        </div>
        <HighchartsComponent
          options={pieChard(
            "Capacidad instalada",
            "",
            burnDataChard.yAxis,
            seriesPie,
            burnDataChard.categories
          )}
        />
      </div>
    </div>
  );
};

export default BiomedicalManagementDashboard;
