import React from "react";
import { Controller } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { styled } from "@mui/material/styles";

const FormCalendar = ({
  name,
  control,
  label,
  defaultValue,
  width,
  onChange,
  formLabel,
  disabled,
  error,
  minDate = null,
  maxDate = null,
}) => {
  const StyledCalendar = styled(({ width, error, ...props }) => (
    <Calendar {...props} baseZIndex={2000} />
  ))(() => ({
    width: width ? width : "100%",
    margin: "0 10px",
    borderRadius: "6px",
    height: "40px",
    borderColor: error ? "red" : undefined,
    border: error ? "1px solid red" : `1px solid #c4c4c4`,
    "& .p-inputtext": {
      padding: "4.5px",
    }
  }));

  return (
    <div style={{ minWidth: 120 }}>
      {label ? <p className="text-form-label">{label}</p> : <></>}

      <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || null}
          render={({ field }) => (
            <>
              <StyledCalendar
                {...field}
                value={field.value || null}
                onChange={(e) => {
                  field.onChange(e.value);
                  onChange && onChange(e.value);
                }}
                placeholder={formLabel}
                dateFormat="mm/dd/yy"
                disabled={disabled}
                showIcon
                minDate={minDate || undefined}
                maxDate={maxDate || undefined}
              />
              {error && <p className="error-message">{error.message}</p>}
            </>
          )}
        />
    </div>
  );
};

export default FormCalendar;
