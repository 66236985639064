import React, { useState, useEffect } from "react";
import ModalComponent from "../../../components/modals";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, useMediaQuery } from "@mui/material";
import * as yup from "yup";
import VerificationModal from "../../../components/modals/verificationModal";
import FormTextField from "../../../components/textField/formTextField";
import FormCalendar from "../../../components/calendar/formCalendar";
import LaddaButton from "../../../components/buttons/laddaButton";
import {
  callApiAsync,
  postSaveAdminBanner
} from "../../../services/apiService";

const validationSchema = yup.object().shape({
  title: yup.string().required("El título es obligatorio"),
  code: yup.string().required("El código es obligatorio"),
  version: yup
    .number()
    .typeError("La versión debe ser un número")
    .required("La versión es obligatoria"),
  page: yup
    .string()
    .typeError("La página debe ser un número")
    .required("La página es obligatoria"),
  date: yup.date().required("La fecha es obligatoria")
});

const EditBannerModal = ({ open, close, data, updateTable }) => {
  const isCustomBreakpoint = useMediaQuery("(max-width:1200px)");
  const [openVerification, setOpenVerification] = useState(false);
  const [leadersSelect, setLeadersSelect] = useState([
    { name: "Líder 1", value: "1" },
    { name: "Líder 2", value: "2" }
  ]);
  const [headerBannerData, setHeaderBannerData] = useState(true);

  const currentYear = new Date().getFullYear();
  const minDate = new Date(currentYear, 0, 1);
  const maxDate = new Date(currentYear, 11, 31);

  const handleOpenVerification = async () => {
    const isValid = await trigger();
    if (isValid) {
      setOpenVerification(true);
    }
  };
  const handleCloseVerification = () => setOpenVerification(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    trigger
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const setDataForm = () => {
    if (!data) return;

    setValue("title", data.title || "");
    setValue("subTitle", data.sub_title || "");
    setValue("code", data.code || "");
    setValue("version", data.version || "");
    setValue("page", data.page || "");
    setValue("date", data.date ? new Date(data.date) : null);
  };

  const handleClose = () => {
    // reset();
    close();
  };

  const handleInputChange = (name, value) => {
    setValue(name, value);
  };

  const handleSaveBannerConfirmed = async () => {
    handleCloseVerification();
    await handleSaveBanner();
  };

  const onSubmit = (formData) => {
    console.log("Formulario enviado:", formData);
  };

  const handleSaveBanner = async () => {
    const formData = getValues();
    const dataToSend = {
      id: data.id ? data.id : null,
      ...formData
    };

    await callApiAsync(
      () => postSaveAdminBanner(dataToSend),
      () => {
        updateTable();
        close();
      }
    );
  };

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        setDataForm();
      }, 50);
    }
  }, [data]);

  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        title="Editar Banner"
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <p className="LabelForm">Título:</p>
              <FormTextField
                name="title"
                control={control}
                error={errors.title}
                width="100%"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <p className="LabelForm">Subtítulo:</p>
              <FormTextField
                name="subTitle"
                control={control}
                error={errors.subTitle}
                width="100%"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <p className="LabelForm">Código:</p>
              <FormTextField
                name="code"
                control={control}
                error={errors.code}
                width="100%"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <p className="LabelForm">Versión:</p>
              <FormTextField
                name="version"
                control={control}
                error={errors.version}
                width="100%"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <p className="LabelForm">Página:</p>
              <FormTextField
                name="page"
                control={control}
                error={errors.page}
                width="100%"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <p className="LabelForm">Fecha:</p>
              <FormCalendar
                name="date"
                control={control}
                error={errors.date}
                width="100%"
                minDate={minDate}
                maxDate={maxDate}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              flexDirection: "column"
            }}
          >
            <LaddaButton
              width={"200px"}
              variant="contained"
              onClick={handleOpenVerification}
            >
              <div className="HContainer align-center">
                <p className="button_text">Guardar</p>
              </div>
            </LaddaButton>
          </div>
        </form>
      </ModalComponent>

      <VerificationModal
        open={openVerification}
        handleClose={handleCloseVerification}
        alertText={
          <>
            <b>¿Estás seguro de guardar los cambios? </b>
          </>
        }
        onConfirm={handleSaveBannerConfirmed}
      />
    </div>
  );
};

export default EditBannerModal;
