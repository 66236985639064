import { Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import "./styles.css";
import { useSelector } from "react-redux";
import SimpleSelect from "../selects/simpleSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextField from "../textField/formTextField";
import FormCalendar from "../calendar/formCalendar";
import LaddaButton from "../buttons/laddaButton";
import {
  canEditByRol,
  getRolbyProcessId
} from "../../screens/dofa/authorizeFunctions";
import {
  callApiAsync,
  getProcessLeaders,
  postSaveStrategiesDofaBanner,
  getStrategiesDofaBanner
} from "../../services/apiService";
import VerificationModal from "../modals/verificationModal";

const StrategiesDofaBanner = ({ rowData }) => {
  const userData = useSelector((state) => state.userData.userData);
  const currentYear = new Date().getFullYear();
  const minDate = new Date(currentYear, 0, 1);
  const maxDate = new Date(currentYear, 11, 31);

  const [bannerData, setBannerData] = useState({});
  const [isAvailableToAdd, setIsAvailableToAdd] = useState(false);
  const [leadersSelect, setLeadersSelect] = useState([]);
  const [isAfterUpdateDate, setIsAfterUpdateDate] = useState(true);
  const [openVerification, setOpenVerification] = useState(false);

  const isCustomBreakpoint = useMediaQuery("(max-width:1200px)");
  const processId = rowData.ProcessId;

  const handleOpenVerification = () => setOpenVerification(true);
  const handleCloseVerification = () => setOpenVerification(false);

  const handleSaveBannerConfirmed = async () => {
    handleCloseVerification();
    await handleSaveBanner();
  };

  const validationSchema = {};
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const isEditable = () => {
    const isEditableByRol = canEditByRol(userData.roleList, processId);

    if (isEditableByRol && isAfterUpdateDate) {
      setIsAvailableToAdd(true);
    } else {
      setIsAvailableToAdd(false);
    }
  };

  const getStrategiesDofaBannerData = async (id) => {
    await callApiAsync(
      () => getStrategiesDofaBanner(id),
      (response) => {
        setBannerData(response);
      }
    );
  };

  const setFormValues = (data) => {
    if (data) {
      setValue("elaborated", data.user_id || "");
      setValue("charge", data.charge || "");
      setValue(
        "productionDate",
        data.elaborated_date ? new Date(data.elaborated_date) : null
      );

      if (data.update_date) {
        const today = new Date();
        const updateDate = new Date(data.update_date);
        setIsAfterUpdateDate(today >= updateDate);
      }
    }
  };

  const getSelects = () => {
    const processRole = getRolbyProcessId(processId);
    getProcessLeadersSelect(processRole);
  };

  const handleSaveBanner = async () => {
    const formData = getValues();
    const dataToSend = {
      processId: processId,
      ...formData
    };

    await callApiAsync(
      () => postSaveStrategiesDofaBanner(dataToSend),
      async (response) => {
        await getStrategiesDofaBannerData(processId);
        isEditable();
      }
    );
  };

  const getProcessLeadersSelect = async (processRole) => {
    await callApiAsync(
      () => getProcessLeaders(processRole),
      (response) => {
        setLeadersSelect(response);
      }
    );
  };

  useEffect(() => {
    if (bannerData) {
      setFormValues(bannerData);
    }
  }, [bannerData]);

  useEffect(() => {
    getStrategiesDofaBannerData(processId);
    getSelects();
  }, []);

  useEffect(() => {
    isEditable();
  }, [isAfterUpdateDate]);

  return (
    <div className="managmentBanner-mini bm-30">
      <form>
        <Grid
          container
          rowSpacing={2}
          sx={{
            alignItems: "center"
          }}
        >
          <Grid item xs={2}>
            <div>
              <p className="detail-label m-0">Elaborado:</p>
            </div>
          </Grid>
          <Grid item xs={12} md={10}>
            <SimpleSelect
              name="elaborated"
              control={control}
              label=""
              options={leadersSelect}
              width="95%"
              disabled={!isAvailableToAdd}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <p className="detail-label m-0">Cargo:</p>
          </Grid>
          <Grid item xs={12} md={10}>
            <FormTextField
              name="charge"
              control={control}
              error={errors.description}
              width="95%"
              rows={1}
              disabled={!isAvailableToAdd}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <p className="detail-label m-0">Fecha elaboración: </p>
          </Grid>
          <Grid item xs={12} md={10}>
            <FormCalendar
              name="productionDate"
              control={control}
              label=""
              defaultValue={null}
              formLabel=""
              error={errors.deliveryDate}
              width="95%"
              disabled={!isAvailableToAdd}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Grid>
        </Grid>
        {isAvailableToAdd && isAfterUpdateDate && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              flexDirection: "column"
            }}
          >
            <LaddaButton
              width={"200px"}
              variant="contained"
              onClick={handleOpenVerification}
            >
              <div className="HContainer align-center">
                <p className="button_text">Guardar</p>
              </div>
            </LaddaButton>
          </div>
        )}
      </form>

      <VerificationModal
        open={openVerification}
        handleClose={handleCloseVerification}
        alertText={
          <>
            <b>¿Estás seguro de guardar los cambios? </b>
            <br />
            Solo se puede agregar esta información una vez al año.
          </>
        }
        onConfirm={handleSaveBannerConfirmed}
      />
    </div>
  );
};

export default StrategiesDofaBanner;
