import React from "react";
import BannerTableAdmin from "./bannerTableAdmin";
import BreadCrumbComp from "../../../components/breadCrumb";

const BannerAdmin = () => {
  return (
    <div className="App">
      <BreadCrumbComp />
      <h1 className="progress-label-title mb-25">Administrador de Banners</h1>
      <BannerTableAdmin />
    </div>
  );
};

export default BannerAdmin;
