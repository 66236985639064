import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import SimpleSelect from "../../../components/selects/simpleSelect";
import ColorIndicator from "../colorIndicator";
import { months, stateDofa } from "../../../constants/selectData";
import { useForm } from "react-hook-form";
import { actualYearSelect } from "../../../services/dateService";
import { getCurrentMonthYear, getDateObj } from "../../../utils/dateUtils";
import { useSelector } from "react-redux";
import { canEditByRol } from "../authorizeFunctions";
import StrategiesComponent from "../../../components/dofaComponent/strategiesComponent";
import {
  callApiAsync,
  getDofaRootAndMonth,
  getDofaStrategyData
} from "../../../services/apiService";
import { getCookie } from "../../../utils/getCookie";
import StrategiesDofaBanner from "../../../components/strategiesDofaBanner";
import { strategiesTypeEnum } from "../../../constants/enums";

const StrategiesTab = ({ data }) => {
  const userData = useSelector((state) => state.userData.userData);
  const [filter, setFilter] = useState(null);
  const [isAvailableToAdd, setIsAvailableToAdd] = useState(false);

  const [debilidades, setDebilidades] = useState([]);
  const [oportunidades, setOportunidades] = useState([]);
  const [fortalezas, setFortalezas] = useState([]);
  const [amenazas, setAmenazas] = useState([]);

  const [strategiesData, setStrategiesData] = useState([]);

  const processId = data.ProcessId;

  const userSesion = getCookie("sesion");

  const typeDofa = [
    { name: "Debilidades", value: 1 },
    { name: "Oportunidades", value: 2 },
    { name: "Fortalezas", value: 3 },
    { name: "Amenazas", value: 4 }
  ];

  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useForm();

  useEffect(() => {
    if (data) {
      setValue("month", data.month || getDateObj().month);
      setValue("year", data.year || getDateObj().year);
      setValue("state", 1);
      getStrategiesByUser(data.year, data.month, 1);
      getSelects(data.year, data.month, processId);
      setFilter({ year: data.year, month: data.month, state: 1 });
      isEditable();
    }
  }, [data]);

  const processData = (data) => {
    const organizedData = {
      Debilidades: [],
      Oportunidades: [],
      Fortalezas: [],
      Amenazas: []
    };

    data.forEach((item) => {
      const type = typeDofa.find((type) => type.value === item.Type)?.name;
      if (type) {
        organizedData[type].push({
          name: item.dofaRootKey,
          value: item.dofaRootId
        });
      }
    });

    return organizedData;
  };

  const getStrategiesByUser = async (year, month, state) => {
    await callApiAsync(
      () => getDofaStrategyData(month, year, data.ProcessId, state),
      (response) => {
        setStrategiesData(response);
      }
    );
  };

  const handleChange = () => {
    isEditable();
  };

  const isEditable = () => {
    const isEditableByRol = canEditByRol(userData.roleList, data.ProcessId);
    const formData = getValues();
    const isEditableByDate = getCurrentMonthYear(formData.year, formData.month);

    if (isEditableByDate && formData.state === 1 && isEditableByRol) {
      setIsAvailableToAdd(true);
    } else {
      setIsAvailableToAdd(false);
    }

    setFilter(formData);
    getStrategiesByUser(formData.year, formData.month, formData.state);
  };

  const getSelects = async (year, month) => {
    await callApiAsync(
      () => getDofaRootAndMonth(year, processId, month),
      (response) => {
        const organizedData = processData(response);
        setDebilidades(organizedData.Debilidades);
        setOportunidades(organizedData.Oportunidades);
        setFortalezas(organizedData.Fortalezas);
        setAmenazas(organizedData.Amenazas);
      }
    );
  };

  return (
    <div>
      <div className="searchTitle">
        <h1 className="page-title">{data.ProcessName}</h1>
      </div>
      <StrategiesDofaBanner rowData={data} />

      <div className="HContainer j-end">
        <ColorIndicator type={"strategies"} />
        <form style={{ display: "flex", margin: "0 20px 0 0" }}>
          <SimpleSelect
            name="state"
            control={control}
            label="Estado"
            options={stateDofa}
            onChange={handleChange}
          />
          <div style={{ margin: "0 10px" }}>
            <SimpleSelect
              name="month"
              control={control}
              label="Mes"
              options={months}
              onChange={handleChange}
            />
          </div>
          <SimpleSelect
            name="year"
            control={control}
            label="Año"
            options={actualYearSelect}
            onChange={handleChange}
          />
        </form>
      </div>

      <Grid
        container
        className="dofa-general-container"
        style={{ backgroundColor: "white" }}
      >
        <Grid item xs={12} md={12}></Grid>

        <Grid item xs={12} md={6}>
          <StrategiesComponent
            data={strategiesData.filter((item) => item.type === strategiesTypeEnum.growth)}
            title="FO - Crecimiento"
            color="#BBF7D0"
            year={filter?.year}
            month={filter?.month}
            processId={data.ProcessId}
            filterdata={filter}
            isAvailableToAdd={isAvailableToAdd}
            Debilidades={debilidades}
            Oportunidades={oportunidades}
            Fortalezas={fortalezas}
            Amenazas={amenazas}
            getStrategiesByUser={getStrategiesByUser}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <StrategiesComponent
            data={strategiesData.filter((item) => item.type === strategiesTypeEnum.redirect)}
            title="DO - Redireccionar"
            color="#FED7AA"
            year={filter?.year}
            month={filter?.month}
            processId={data.ProcessId}
            filterdata={filter}
            isAvailableToAdd={isAvailableToAdd}
            Debilidades={debilidades}
            Oportunidades={oportunidades}
            Fortalezas={fortalezas}
            Amenazas={amenazas}
            getStrategiesByUser={getStrategiesByUser}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <StrategiesComponent
            data={strategiesData.filter((item) => item.type === strategiesTypeEnum.protection)}
            title="FA - Protección"
            color="#BAE6FD"
            year={filter?.year}
            month={filter?.month}
            processId={data.ProcessId}
            filterdata={filter}
            isAvailableToAdd={isAvailableToAdd}
            Debilidades={debilidades}
            Oportunidades={oportunidades}
            Fortalezas={fortalezas}
            Amenazas={amenazas}
            getStrategiesByUser={getStrategiesByUser}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StrategiesComponent
            data={strategiesData.filter((item) => item.type === strategiesTypeEnum.survive)}
            title="DA - Sobrevivir"
            color="#FECDD3"
            year={filter?.year}
            month={filter?.month}
            processId={data.ProcessId}
            filterdata={filter}
            isAvailableToAdd={isAvailableToAdd}
            Debilidades={debilidades}
            Oportunidades={oportunidades}
            Fortalezas={fortalezas}
            Amenazas={amenazas}
            getStrategiesByUser={getStrategiesByUser}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default StrategiesTab;
