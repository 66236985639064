import React from "react";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import "../styles.css"; 
import { useMediaQuery } from "@mui/material";

const SimpleSelect = ({
  name,
  control,
  label,
  options,
  onChange,
  defaultValue,
  width,
  error,
  required,
  hideLabel,
  disabled 
}) => {
  const isSmallScreen = useMediaQuery("(max-width:590px)");

  const StyledSelect = styled(Select)(() => ({
    width: width ? width : "100%",
    height: "40px",
    margin: "0 10px 0 10px",
    borderRadius: "6px",
    marginTop: isSmallScreen ? '8px' : '',
  }));

  const StyledInputLabel = styled(InputLabel)(() => ({
    top: '-10px',
    marginTop: isSmallScreen ? '10px' : '',
    color: hideLabel ? 'white' : '',
    "&.Mui-error": {
      color: hideLabel ? 'white' : '',
    },
    "&.Mui-focused": {
      color: hideLabel ? 'white' : '',
    },
  }));

  return (
    <Box>
      <FormControl fullWidth error={Boolean(error)}>
        <StyledInputLabel shrink={true} required={required} id={`${name}-label`}>
          {label}
        </StyledInputLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || ""}
          render={({ field }) => (
            <StyledSelect
              {...field}
              value={field.value}
              labelId={`${name}-label`}
              id={name}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e.target.value);
              }}
              disabled={disabled}
            >
              {options.map((option, index) => (
                <MenuItem key={option.name} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        />
        {error && (
          <p
            className="error-message"
            style={{ marginTop: "5px", color: "red" }}
          >
            {error.message}
          </p>
        )}
      </FormControl>
    </Box>
  );
};

export default SimpleSelect;
