import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);

export const horizontalColumn = (
  title,
  subtitle,
  series,
  categories,
  umbral
) => {
  // Calcula el valor mínimo y máximo del eje Y
  const allValues = series.flatMap((serie) => serie.data || []); // Extraer todos los valores
  const maxValue = Math.max(...allValues, umbral?.value || 0); // Asegura que el umbral se considere para el máximo
  const minValue = Math.min(...allValues); // Mínimo basado en los datos
  const yAxisMax = maxValue > 8 ? maxValue : 8; // Garantiza que el eje muestre al menos hasta 8

  return {
    chart: {
      type: "bar"
    },
    title: {
      text: title,
      align: "left"
    },
    subtitle: {
      text: subtitle,
      align: "left"
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      accessibility: {
        description: "Countries"
      },
      labels: {
        style: { fontSize: "12px" }
      }
    },
    yAxis: {
      min: 0, // Siempre comienza en 0
      max: yAxisMax, // Asegura que el eje Y muestre el umbral
      title: {
        text: ""
      },
      plotLines:
        umbral != null
          ? [
              {
                color: "red",
                width: 2,
                value: umbral.value, // Valor del umbral
                zIndex: 5,
                label: {
                  text: "Umbral", // Etiqueta del umbral
                  align: "center",
                  x: -20
                }
              }
            ]
          : []
    },
    tooltip: {
      valueSuffix: ""
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      },
      dataLabels: {
        enabled: true,
        format: "{y}"
      }
    },
    series: series
  };
};
