import React from "react";
import { useWatch } from "react-hook-form";

const CharacterCounter = ({ name, control, maxLength }) => {
  const value = useWatch({ control, name }) || "";

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <p style={{ fontSize: "10px", color: "#757575", fontFamily: "Arial" }}>
        {value.length}/{maxLength} caracteres
      </p>
    </div>
  );
};

export default CharacterCounter;
