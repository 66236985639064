import { getYear, subYears, format, parseISO } from "date-fns";

const currentYear = getYear(new Date());

export const actualYearSelect = Array.from({ length: 6 }, (_, index) => {
  const year = getYear(subYears(new Date(), index));
  return { name: year.toString(), value: year };
});

export function formatWithoutHour(fechaCompleta) {
  if (!fechaCompleta) return;

  const fecha = parseISO(fechaCompleta);
  const fechaSinHora = format(fecha, "yyyy-MM-dd");

  return fechaSinHora;
}
