import React, { useEffect, useState } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import SimpleTable from "../../components/tables/simpleTable";
import {
  superior_rol_A_BurnData,
  superior_rol_B_BurnData,
  superior_rol_C_BurnData
} from "../../utils/burnData";
import LaddaButton from "../../components/buttons/laddaButton";
import { icons } from "../../constants";
import { API_URL } from "../../config";
import SearchBar from "../../components/searchBar";
import { useForm } from "react-hook-form";
import SimpleSelect from "../../components/selects/simpleSelect";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/getCookie";
import axios from "axios";
import StudyMaterialsModal from "./studyMaterialsModal";
import { getDateObj } from "../../utils/dateUtils";
import ModalComponent from "../../components/modals";
import MediaViewer from "../../components/mediaViewer";
import "./styles.css";
import {
  callApiAsync,
  getCallActivitiesByCompetency,
  getCallAllEmployeesInMonitoring,
  getCallEmployeesInMonitoring,
  getCallPositionList,
  getCallSelfAppraisal
} from "../../services/apiService";
import { isAdmin, isGeneralManagment } from "./authorizeFunctions";
import { useDispatch, useSelector } from "react-redux";
import ModalEditComment from "./employeesMonitored/commentModal";
import { actualYearSelect } from "../../services/dateService";
import { changeDirectorGestionBtnState } from "../../redux/slices/navigationSlice";

const SuperiorRolDashboard = () => {
  const userData = useSelector((state) => state.userData.userData);
  const navigate = useNavigate();
  const userSesion = getCookie("sesion");
  const currentUserId = userSesion.id_usuario;
  const { control, getValues, setValue } = useForm();
  const [userInfo, setUserInfo] = useState("");
  const [seftTable, setSeftTable] = useState([]);
  const [allSeftTable, setAllSeftTable] = useState([]);
  const [allSeftTableFiltered, setAllSeftTableFiltered] = useState([]);
  const [openStudyMaterialsModal, setOpenStudyMaterialsModal] = useState(false);
  const [modalTableData, setModalTableData] = useState({});
  const [openModalEditComment, setOpenModalEditComment] = useState(false);
  const [currentCommentRow, setCurrentCommentRow] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [activitiesTable, setActivitiesTable] = useState([]);
  const [mediaModal, setMediaModal] = useState(false);
  const [mediaData, setMediaData] = useState({});

  const [positionList, setPositionList] = useState([]);

  const handleChangeFilter = () => {
    var positionSelected = getValues().position;
    var searchTextSelected = getValues().searchText;
    const filteredData = allSeftTable.filter((item) => {
      const matchesPosition =
        positionSelected == 0 || item.positionId == positionSelected;
      const matchesSearchText =
        !searchTextSelected ||
        (item.employee &&
          item.employee
            .toLowerCase()
            .includes(searchTextSelected.toLowerCase()));
      return matchesPosition && matchesSearchText;
    });

    setAllSeftTableFiltered(filteredData);
  };

  const dispatch = useDispatch();

  const handleFileUpload = (fileName) => {
    setUploadedFileName(fileName);
  };

  const handleChangeYear = () => {
    getEmployeesInMonitoring();
  };

  const getUserInfo = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/getCurrentUser/${currentUserId}`
      );
      setUserInfo(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getEmployeesInMonitoring = async () => {
    const { year, position, searchText } = getValues();
    callApiAsync(
      () => getCallEmployeesInMonitoring(currentUserId, year),
      (data) => {
        const responseData = data;
        const filteredData = responseData.filter((item) => {
          const matchesPosition =
            position === 0 || item.positionId === position;
          const matchesSearchText =
            !searchText ||
            (item.employee &&
              item.employee.toLowerCase().includes(searchText.toLowerCase()));

          return matchesPosition && matchesSearchText;
        });
        setAllSeftTableFiltered(filteredData);
        setAllSeftTable(responseData);
      }
    );
  };

  const getAllEmployeesInMonitoring = async () => {
    const { year, position, searchText } = getValues();
    callApiAsync(
      () => getCallAllEmployeesInMonitoring(currentUserId),
      (data) => {
        const responseData = data;
        const filteredData = responseData.filter((item) => {
          const matchesPosition =
            position === 0 || item.positionId === position;
          const matchesSearchText =
            !searchText ||
            (item.employee &&
              item.employee.toLowerCase().includes(searchText.toLowerCase()));

          return matchesPosition && matchesSearchText;
        });
        setAllSeftTableFiltered(filteredData);
        setAllSeftTable(responseData);
      }
    );
  };

  const getSelfAppraisal = async () => {
    callApiAsync(
      () => getCallSelfAppraisal(currentUserId),
      (data) => {
        setSeftTable(data);
      }
    );
  };

  const getActivitiesByCompetency = async () => {
    callApiAsync(
      () => getCallActivitiesByCompetency(currentUserId),
      (data) => {
        setActivitiesTable(data);
      }
    );
  };

  const loadDataSelects = async () => {
    setValue("year", getDateObj().year);
    setValue("position", 0);
  };

  const getPositionFx = () => {
    callApiAsync(
      () => getCallPositionList(),
      (data) => {
        setPositionList([{ name: "Todos", value: 0 }, ...data]);
      }
    );
  };

  const getUsersInMonitoringFx = () => {
    if (isAdmin(userData.roleList) || isGeneralManagment(userData.roleList)) {
      getAllEmployeesInMonitoring();
    } else {
      getEmployeesInMonitoring();
    }
  };

  useEffect(() => {
    loadDataSelects();
    getUserInfo();
    getActivitiesByCompetency();
    getSelfAppraisal();
    getUsersInMonitoringFx();
    getPositionFx();
    dispatch(changeDirectorGestionBtnState(false));
  }, []);

  const PillIndicator = ({ type }) => {
    switch (type) {
      case 0:
        return (
          <div className="pill-indicator pill-yellow">
            <p className="pillText">Semi Senior</p>
          </div>
        );
      case 1:
        return (
          <div className="pill-indicator pill-yellow">
            <p className="pillText">Senior</p>
          </div>
        );

      default:
        return (
          <div className="pill-indicator pill-grey">
            <p></p>
          </div>
        );
    }
  };

  const openMediaModal = (data) => {
    if (data.type === "Link") {
      window.open(data.url, "_blank");
      return;
    }
    setMediaModal(true);
    setMediaData(data);
  };

  const hadleClose = () => {
    setMediaModal(false);
    setMediaData({});
  };

  const expansionTemplate = (data) => {
    return (
      <div className="HContainer j-space-between">
        <div className="title-container-study-materials">
          <p className="expansion-text">Material de estudio</p>
        </div>
        <div className="HContainer">
          <div className="HContainer align-center">
            {data.material.map((item) => {
              return item.type === "link" ? (
                <a
                  href={item.url}
                  className="url-column"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LaddaButton
                    height={"32px"}
                    color={"#22C55E"}
                    backgroundColor={"#D3FFE2"}
                    variant="contained"
                    onClick={() => console.log("url", item.url)}
                  >
                    <div className="HContainer align-center">
                      <img
                        className="btn-icon"
                        src={icons.archivo}
                        alt="icon"
                        style={{ marginRight: "10px" }}
                      />
                      <p className="button_text">{item.type}</p>
                    </div>
                  </LaddaButton>
                </a>
              ) : (
                <div key={item.id} className="other-item">
                  <LaddaButton
                    height={"32px"}
                    color={"#22C55E"}
                    backgroundColor={"#D3FFE2"}
                    variant="contained"
                    onClick={() => openMediaModal(item)}
                  >
                    <div className="HContainer align-center">
                      <img
                        className="btn-icon"
                        src={icons.archivo}
                        alt="icon"
                        style={{ marginRight: "10px" }}
                      />
                      <p className="button_text">{item.type}</p>
                    </div>
                  </LaddaButton>
                </div>
              );
            })}
          </div>
          <div className="HContainer align-center">
            {/* <div className="HContainer align-center">
              <FileUpload setResult={handleFileUpload} />
              <div>
                <LaddaButton
                  height={"32px"}
                  color={"#6A6AD8"}
                  backgroundColor={"#F8F8FD"}
                  variant="contained"
                  onClick={console.log("url")}
                >
                  <div className="HContainer align-center">
                    <p>{uploadedFileName ? uploadedFileName : "Evidencia"}</p>
                  </div>
                </LaddaButton>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const openMaterialModal = (data) => {
    setModalTableData(data);
    setOpenStudyMaterialsModal(true);
  };

  return (
    <div className="App">
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1 className="page-title">DASHBOARD - {userInfo.nombre}</h1>
      </div>
      <div className="-">
        {isAdmin(userData.roleList) === false ? (
          <>
            <div className="table-container">
              <div className="header-data-container">
                <p className="info-label">
                  Empleado:
                  <span className="label_header_text">{userInfo.nombre}</span>
                </p>
                <p className="info-label">
                  Cargo:
                  <span className="label_header_text">{userInfo.cargo}</span>
                </p>
                <p className="info-label">
                  Proceso:
                  <span className="label_header_text">{userInfo.area}</span>
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "unset",
                  marginBottom: "20px"
                }}
              ></div>
              <SimpleTable
                data={seftTable}
                columns={superior_rol_A_BurnData.columns}
                scrollHeight={"300px"}
                // text={"Realizar Autoevaluación"}
                buttons={[
                  {
                    type: "redirectData",
                    icon: icons.lineChard,
                    url: "/360_Report"
                  }
                ]}
              />
            </div>
            <div className="table-container">
              <h1 className="progress-label-title">Actividades</h1>
              <SimpleTable
                data={activitiesTable}
                columns={superior_rol_B_BurnData.columns}
                scrollHeight={"500px"}
                rowExpansionTemplate={expansionTemplate}
                text={"Realizar Autoevaluación"}
                buttons={[
                  {
                    type: "icon",
                    icon: icons.eye,
                    onClick: openMaterialModal
                  }
                ]}
              />
            </div>
            <div className="table-container">
              <h1 className="progress-label-title">
                Plan de Acción Individual - PAI 360 grados
              </h1>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Link to="/superior_rol_dashboard/Pai_360" state={{ paiId: 1 }}>
                  <LaddaButton
                    variant="contained"
                    color={"black"}
                    backgroundColor={"#F4D658"}
                    width={"230px"}
                  >
                    <div className="HContainer align-center">
                      <p className="button_text">PAI</p>
                    </div>
                  </LaddaButton>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="table-container">
          <h1 className="progress-label-title">Empleados en Seguimiento</h1>
          <div className="filter-container">
            <form style={{ display: "flex", alignItems: "center" }}>
              <SearchBar
                width="300px"
                name="searchText"
                control={control}
                onSearch={handleChangeFilter}
              />
              <br style={{ marginRight: "20px" }} />
              <SimpleSelect
                name="position"
                width="200px"
                control={control}
                label="Cargo"
                options={positionList}
                onChange={handleChangeFilter}
              />
              <SimpleSelect
                name="year"
                width="200px"
                control={control}
                label="Año"
                options={actualYearSelect}
                onChange={handleChangeYear}
              />
            </form>
          </div>
          <SimpleTable
            data={allSeftTableFiltered}
            columns={superior_rol_C_BurnData.columns}
            text={"Realizar Autoevaluación"}
            isPaginated={true}
            sortable
            buttons={[
              {
                type: "icon",
                icon: icons.editBlue,
                onClick: (rowData) => {
                  setCurrentCommentRow(rowData);
                  setOpenModalEditComment(true);
                }
              },
              {
                type: "redirectData",
                icon: icons.lineChard,
                url: "/360_Report"
              }
            ]}
          />
        </div>
      </div>
      <ModalEditComment
        open={openModalEditComment}
        handleClose={() => setOpenModalEditComment(false)}
        currentCommentRow={currentCommentRow}
        getUsersInMonitoringFx={getUsersInMonitoringFx}
      />
      <ModalComponent
        open={mediaModal}
        handleClose={hadleClose}
        title={""}
        actions={<></>}
      >
        <div
          className={`${
            mediaData?.type === "PDF" ? "media-pdf-modal" : "media-modal"
          }`}
        >
          <MediaViewer src={mediaData?.url} type={mediaData?.type} />
        </div>
      </ModalComponent>

      <StudyMaterialsModal
        open={openStudyMaterialsModal}
        handleClose={() => setOpenStudyMaterialsModal(false)}
        data={modalTableData}
      />
    </div>
  );
};

export default SuperiorRolDashboard;
