export const verticalGroupColumns = (
  title,
  subtitle,
  yAxisText,
  series,
  categories,
  umbral
) => {
  const allValues = series.flatMap((serie) => serie.data || []);
  const maxValue = Math.max(...allValues, umbral?.value || 0);
  const yAxisMax = maxValue > 8 ? maxValue : 8;

  return {
    chart: {
      type: "column"
    },
    title: {
      text: title,
      align: "left",
      style: {
        fontSize: "19px",
        fontWeight: "bold",
        color: "#333"
      }
    },
    subtitle: {
      text: subtitle,
      align: "left",
      style: {
        fontSize: "15px",
        margin: "10px"
      }
    },
    xAxis: {
      categories: categories,
      title: {
        text: null
      },
      labels: {
        style: {
          fontSize: "16px"
        }
      }
    },
    yAxis: {
      min: 0,
      max: yAxisMax,
      title: {
        text: yAxisText,
        style: {
          fontSize: "16px",
          color: "#666"
        },
        // align: "high",
        margin: 20
      },
      plotLines:
        umbral != null
          ? [
              {
                color: "red",
                width: 2,
                value: umbral.value,
                zIndex: 5,
                label: {
                  text: "Umbral",
                  align: "right",
                  x: -20
                }
              }
            ]
          : [],
      labels: {
        overflow: "justify",
        style: {
          fontSize: "14px",
          color: "#666"
        }
      },
      gridLineWidth: 0
    },
    tooltip: {
      valueSuffix: "",
      headerFormat: '<span style="font-size:16px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="font-size:16px;color:{series.color};padding:0">{series.name}:   </td>' +
        '<td style="font-size:16px;padding:0">{point.y}' +
        '<td style="font-size:16px;padding:0"><b>{point.percentage:.0f} </b>({point.y:.0f}%)',
      footerFormat: "</table>",
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      x: 0,
      y: 0,
      floating: false,
      borderWidth: null,
      // backgroundColor:
      //   Highcharts.defaultOptions.legend.backgroundColor || "white",
      shadow: false,
      itemStyle: {
        fontSize: "14px"
      }
    },
    credits: {
      enabled: false
    },
    series: series
  };
};
