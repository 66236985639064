import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbComp from "../../components/breadCrumb";
import LaddaButton from "../../components/buttons/laddaButton";
import HighchartsComponent from "../../components/highchartsComponent";
import SimpleSelect from "../../components/selects/simpleSelect";
import SimpleTable from "../../components/tables/simpleTable";
import SearchBar from "../../components/searchBar";
import { icons } from "../../constants";
import { useForm } from "react-hook-form";
import { months } from "../../constants/selectData";
import { dasboardBioMedicalBurnData } from "../../utils/burnData";

import { columnChartOptions } from "../../components/highchartsComponent/charts/columns";
import { pieChard } from "../../components/highchartsComponent/charts/pieChard";
import { horizontalColumn } from "../../components/highchartsComponent/charts/horizontalColumn";
import { fixedPlacementColumns } from "../../components/highchartsComponent/charts/fixedPlacementColumns";
import "./styles.css";
import { actualYearSelect } from "../../services/dateService";

const seriesAGraph = [
  {
    name: "Mantenimiento a realizar en el mes",
    data: [12, 11, 23]
  },
  {
    name: "Mantenimiento hechos en el mes",
    data: [15, 13, 28]
  }
];
const categoriesAGraph = ["Nataly", "Juan", "Total"];

const burnDataChard = {
  title: "Presupuesto de Venta vs. Venta Real",
  subtitle: "",
  chard: horizontalColumn,
  yAxis: "Miles de Millones",
  series: [
    {
      name: "Suma de Presupuesto_Valores",
      data: [1.1, 1.2, 1.4, 2, 2.2, 2.4, 2.5, 2.5, 2.6, 2.7, 2.7]
    },
    {
      type: "line",
      step: "center",
      name: "Suma de Ventas Real",
      data: [1.1, 1.2, 1.4, 2, 2.2, 2.4, 2.5, 2.5, 2.6, 2.7, 2.7],
      marker: {
        lineWidth: 2,
        lineColor: "#6A6AD8",
        fillColor: "white"
      }
    }
  ],
  categories: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Sep",
    "Oct",
    "Nov",
    "Dic"
  ]
};

const seriesPie = [
  {
    name: "Percentage",
    colorByPoint: true,
    data: [
      {
        name: "Equipos Importados",
        y: 15.8
      },
      {
        name: "Equipos Instalados",
        sliced: true,
        selected: true,
        y: 56.0
      },
      {
        name: "Equipos de Alta de Inventario",
        y: 27.2
      },
      {
        name: "Equipos listos para instalar",
        y: 1
      }
    ]
  }
];

const categoriesColumn = [
  "Requerimiento 1",
  "Requerimiento 2",
  "Requerimiento 3"
];

const seriesColumn = [
  {
    name: "A",
    color: "rgba(165,170,217,1)",
    data: [150, 73, 20],
    pointPadding: 0.3,
    pointPlacement: -0.2
  },
  {
    name: "B",
    color: "rgba(126,86,134,.9)",
    data: [140, 90, 40],
    pointPadding: 0.4,
    pointPlacement: -0.2
  },
  {
    name: "C",
    color: "rgba(248,161,63,1)",
    data: [183.6, 178.8, 198.5],

    pointPadding: 0.3,
    pointPlacement: 0.2
  },
  {
    name: "D",
    color: "rgba(186,60,61,.9)",
    data: [203.6, 198.8, 208.5],

    pointPadding: 0.4,
    pointPlacement: 0.2
  }
];

const ManagementDashboardIndicator = () => {
  const [chartData, setChartData] = useState({});

  const { control: controlChart, getValues: getValuesChart } = useForm();
  const { control: controlTable, getValues: getValuesTable } = useForm();
  let location = useLocation();
  let state = location.state || {};

  const getTitle = (key) => {
    switch (key) {
      case "maintenance":
        return "Indicador de Mantenimiento";
      case "downtime":
        return "Indicador de Tiempo de Parada";
      case "installedCapacity":
        return "Indicador de Capacidad Instalada";
      default:
        break;
    }
  };

  const getChartData = (key) => {
    switch (key) {
      case "maintenance":
        return (
          <></>
          // <HighchartsComponent
          //   options={horizontalColumn(
          //     "Mantenimientos 2024",
          //     "",
          //     "Cantidad",
          //     seriesAGraph,
          //     categoriesAGraph
          //   )}
          // />
        );
      case "downtime":
        return (
          <HighchartsComponent
            options={fixedPlacementColumns(
              "Tiempo de parada",
              "",
              "Dias desde que el equipo entre en Mantenimiento",
              seriesColumn,
              categoriesColumn
            )}
          />
        );
      case "installedCapacity":
        return (
          <HighchartsComponent
            options={pieChard(
              "Capacidad instalada",
              "",
              burnDataChard.yAxis,
              seriesPie,
              burnDataChard.categories
            )}
          />
        );
      default:
        break;
    }
  };

  const handleChange = () => {
    const formData = getValuesChart();
  };

  const handleChangeTable = () => {
    const formData = getValuesTable();
  };

  return (
    <div>
      <BreadCrumbComp personalized={() => getTitle()} />
      <div className="HContainer j-space-between">
        <h1 className="mayus title-bio-details">{getTitle(state.type)}</h1>

        {state.type === "downtime" ? (
          <LaddaButton
            variant="contained"
            color={"#404040"}
            backgroundColor={"#F4D658"}
            width={"200px"}
            height={"55px"}
            onClick={() => {}}
          >
            <div className="HContainer align-center">
              <img
                className="btn-icon"
                src={icons.rightBlack}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              <p className="button_text">Alertas Dispositivos</p>
            </div>
          </LaddaButton>
        ) : (
          <></>
        )}
      </div>

      <div className="table-container">
        <br className="br-white-space" />
        <div className="HContainer j-space-between">
          <p className="title-bio-details-chard">{`Resultados ${getTitle(
            state.type
          )}`}</p>

          <div>
            <form
              style={{ display: "flex", display: "flex", alignItems: "center" }}
            >
              <SimpleSelect
                name="month"
                control={controlChart}
                label="Mes"
                options={months}
                onChange={handleChange}
                width="200px"
                // defaultValue={dateObj.month}
              />
              <SimpleSelect
                name="year"
                control={controlChart}
                label="Año"
                options={actualYearSelect}
                onChange={handleChange}
                width="200px"
                // defaultValue={dateObj.year}
              />
            </form>
          </div>
        </div>
        <div>{getChartData(state.type)}</div>
        {state.type === "downtime" ? (
          <div>
            <br className="br-white-space" />

            <div>
              <form
                style={{
                  display: "flex",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <SearchBar
                  width="300px"
                  name="searchText"
                  control={controlTable}
                  onSearch={handleChangeTable}
                  label={"Equipo"}
                />
                <br style={{ marginRight: "20px" }} />
                <SimpleSelect
                  name="institution"
                  control={controlTable}
                  label="Institución"
                  options={months}
                  onChange={handleChangeTable}
                  width="200px"
                  // defaultValue={dateObj.month}
                />
                <SimpleSelect
                  name="followState"
                  control={controlTable}
                  label="Estado de Seguimiento"
                  options={months}
                  onChange={handleChangeTable}
                  width="200px"
                  // defaultValue={dateObj.month}
                />
                <SimpleSelect
                  name="realizationDate"
                  control={controlTable}
                  label="Fecha de Realización"
                  options={actualYearSelect}
                  onChange={handleChangeTable}
                  width="200px"
                  // defaultValue={dateObj.year}
                />
              </form>
            </div>

            <br className="br-white-space" />

            <SimpleTable
              //data={paiInfo}
              data={dasboardBioMedicalBurnData.data}
              columns={dasboardBioMedicalBurnData.columns}
              // scrollHeight={"300px"}
              text={"Realizar Autoevaluación"}
              isPaginated={true}
              sortable
              buttons={[
                {
                  type: "icon",
                  icon: icons.eye,
                  onClick: () => {
                    console.log("redirigir al PAI con el id");
                  }
                }
              ]}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ManagementDashboardIndicator;
