import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import SimpleSelect from "../../components/selects/simpleSelect";
import { Divider, Grid, useMediaQuery } from "@mui/material";
import FormTextField from "../../components/textField/formTextField";
import LaddaButton from "../../components/buttons/laddaButton";
import FormRadioButton from "../../components/formRadioButton";
import { icons } from "../../constants";
import SelectSerieNumber from "./modals/selectSerieNumberModal";
import AddSerieNumber from "./modals/addSerieNumberModal";
import {
  callApiAsync,
  getAdverseEventsBiomedicalEquipment,
  getCallBiomedicalEquipmentBySerie,
  getCallSelectsBiomedicalEquipment,
  getSelectsBiomedical,
  postSaveBiomedicalEquipment
} from "../../services/apiService";
import FormCalendar from "../../components/calendar/formCalendar";
import ToastNotification from "../../components/toastNotification";
import ImageUploadButton from "../../components/ImageUploadBtn";
import { useDispatch, useSelector } from "react-redux";
import SimpleTable from "../../components/tables/simpleTable";
import { accesoriesColumns } from "./columns";
import AddAccessoryBase from "./modals/addAccessoryBase";
import { setBiomedicalId } from "../../redux/slices/biomedicalSlice";
import { useNavigate } from "react-router-dom";
import { yesNoSelect } from "../../utils/burnData";
import AddUbicationModal from "./modals/addUbicationModal";
import AdverseEventsTable from "./adverseEventsTable";
import { format } from "date-fns";
import { technologyModalityOption } from "../../constants/selectData";

const urlApi = process.env.REACT_APP_API_URL;

const validationSchema = yup.object().shape({
  name: yup.string().required("Este campo es obligatorio"),
  statusText: yup.string().required("Este campo es obligatorio"),
  brand: yup.string().required("Este campo es obligatorio"),
  model: yup.string().required("Este campo es obligatorio"),
  quantity: yup
    .number()
    .typeError("La cantidad debe ser un número")
    .integer("La cantidad debe ser un número entero")
    .required("Este campo es obligatorio"),
  importer: yup.string().required("Este campo es obligatorio"),
  producer: yup.string().required("Este campo es obligatorio"),
  classificationRisk: yup.string().required("Este campo es obligatorio"),
  importDate: yup.date().required("Este campo es obligatorio")
});

const BiomedicalEquipmentBase = () => {
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery("(max-width:590px)");

  const [openSelectSerieNumberModal, setOpenSelectSerieNumberModal] =
    useState(false);
  const [openAddSerieNumberModal, setOpenAddSerieNumberModal] = useState(false);
  const [openAddAccessoryModal, setOpenAddAccessoryModal] = useState(false);

  const [brandOptions, setBrandOptions] = useState([]);
  const [importerOptions, setImporterOptions] = useState([]);
  const [producerOptions, setProducerOptions] = useState([]);
  const [classificationRiskOptions, setClassificationRiskOptions] = useState(
    []
  );
  const [biomedicalEquipmentId, setBiomedicalEquipmentId] = useState(0);
  const [serieIdSelected, setSerieIdSelected] = useState(null);
  const [serieSelected, setSerieSelected] = useState("");
  const [biomedicalEquipmentData, setBiomedicalEquipmentData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [accessoryTable, setAccessoryTable] = useState([]);
  const [brandIdSelected, setBrandIdSelected] = useState(null);
  const [severityState, setSeverityState] = useState("success");
  const [adverseEventsSelect, setAdverseEvents] = useState([]);
  const [installedSelect, setInstalledSelect] = useState([]);
  const [biomedicalEquipmentState, setBiomedicalEquipmentState] = useState([]);
  const [currentLocationSelect, setCurrentLocationSelect] = useState([]);
  const [openAddUbication, setOpenAddUbication] = useState(false);
  const [adverseEventsDataTable, setAdverseEventsDataTable] = useState([]);

  const navigate = useNavigate();

  const biomedicalId = useSelector((state) => state.biomedical.biomedicalId);
  const serieIdRedux = useSelector(
    (state) => state.biomedical.equipementSerieId
  );

  const onChangeBrand = () => {
    var brandIdCurrent = getValues("brand");
    setBrandIdSelected(brandIdCurrent);
    setAccessoryTable([]);
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all"
  });
  const statusValue = watch("status");

  const options = [
    { label: "Registro Sanitario", value: 1 },
    { label: "Permiso de comercialización", value: 2 }
  ];

  const getAdverseEventsDataTable = () => {
    callApiAsync(
      () => getAdverseEventsBiomedicalEquipment(biomedicalEquipmentId),
      (data) => {
        setAdverseEventsDataTable(data);
      }
    );
  };

  useEffect(() => {
    getSelectsBiomedicalEquipment();
    if (serieIdRedux) {
      setSerieIdSelected(serieIdRedux);
    }
  }, []);

  useEffect(() => {
    getAdverseEventsDataTable(biomedicalEquipmentId);
  }, [biomedicalEquipmentId]);

  useEffect(() => {
    setValue("serieText", serieSelected);

    if (serieIdSelected && serieIdSelected != 0) {
      getBiomedicalEquipmentBySerie();
    } else {
      cleanData();
    }
  }, [serieSelected, biomedicalId, serieIdSelected]);

  const hadleModalClose = () => {
    setOpenSelectSerieNumberModal(false);
  };

  const hadleModalCloseAdd = () => {
    setOpenAddSerieNumberModal(false);
  };

  const hadleCloseAddAccessory = () => {
    setOpenAddAccessoryModal(false);
  };

  const getSelectsBiomedicalEquipment = async () => {
    callApiAsync(
      () => getCallSelectsBiomedicalEquipment(),
      (data) => {
        setBrandOptions(data.brands);
        setClassificationRiskOptions(data.riskClassifications);
        setProducerOptions(data.producers);
        setImporterOptions(data.importers);
      }
    );
  };

  const getSelectsBiomedicalData = () => {
    callApiAsync(
      () => getSelectsBiomedical(),
      (data) => {
        setInstalledSelect(data.installed);
        setAdverseEvents(data.adverseEvents);
        setBiomedicalEquipmentState(data.state);
        setCurrentLocationSelect(data.ubication);
      }
    );
  };

  const getBiomedicalEquipmentBySerie = async (serieIdR) => {
    callApiAsync(
      () =>
        getCallBiomedicalEquipmentBySerie(
          serieIdR == null ? serieIdSelected : serieIdR
        ),
      (data) => {
        setBiomedicalEquipmentData(data);
        setBiomedicalEquipmentId(data.id);
        loadData(data);
        setAccessoryTable(data.accessories);
      }
    );
  };

  useEffect(() => {
    getSelectsBiomedicalData();
  }, []);

  const loadData = (data) => {
    if (data.imagePath) {
      const correctedImagePath = data.imagePath.replace(/\\/g, "/");
      const newImagePath = correctedImagePath.replace(
        "biomedicalEquipment/",
        ""
      );
      const finalImageUrl = `${urlApi}${newImagePath}`;
      setSelectedImage(finalImageUrl);
    } else {
      setSelectedImage("");
    }

    setBrandIdSelected(data.brandId);

    setValue(
      "serieText",
      data.serieName == null ? serieSelected : data.serieName
    );
    setValue("name", data.name);
    setValue("brand", data.brandId);
    setValue("model", data.model);
    setValue("quantity", data.amount);
    setValue("importer", data.importerId);
    setValue("producer", data.producerId);
    setValue("classificationRisk", data.riskClassificationId);
    setValue("importDate", data.importDate ? new Date(data.importDate) : null);
    setValue("status", data.healthOrMarketing);
    setValue("statusText", data.healthOrMarketingText);
    setValue(
      "installed",
      data.installed === 1 ? 1 : data.installed === 0 ? 0 : null
    );
    if (data.installationDate) {
      setValue("installationDate", new Date(data.installationDate));
    }
    if (data.marketingDate) {
      setValue("marketingDate", new Date(data.marketingDate));
    }
    setValue("adverseEvents", data.adverseEventId);
    setValue("state", data.stateId);
    setValue("currentLocation", data.currentLocationId);
  };

  const cleanData = () => {
    setSelectedImage(null);
    setValue("name", "");
    setValue("brand", "");
    setValue("model", "");
    setValue("quantity", "");
    setValue("importer", "");
    setValue("producer", "");
    setValue("classificationRisk", "");
    setValue("importDate", null);
    setValue("status", "");
    setValue("statusText", "");
  };

  const handleImageChange = (file) => {
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  async function blobUrlToFile(blobUrl) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new File([blob], "image.jpg", { type: blob.type });
  }

  const onSubmit = async (formData) => {
    if (serieIdSelected == null) {
      return;
    }
    const accessoryIds = accessoryTable.map((item) => item.id);

    const combinedData = {
      id: biomedicalEquipmentId,
      name: formData.name,
      brandId: formData.brand,
      model: formData.model,
      amount: formData.quantity,
      importerId: formData.importer,
      producerId: formData.producer,
      riskClassificationId: formData.classificationRisk,
      importDate: format(
        new Date(formData.importDate),
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      healthOrMarketing: formData.status,
      healthOrMarketingText: formData.statusText,
      serieId: serieIdSelected,
      serieName: serieSelected,
      adverseEventsDataTable: adverseEventsDataTable,
      accessoryIds,
      installedValue:
        getValues("installed") === 1
          ? 1
          : getValues("installed") === 0
          ? 0
          : null,
      installationDate:
        getValues("installationDate") === null
          ? null
          : getValues("installed") === 1
          ? format(
              new Date(getValues("installationDate")),
              "yyyy-MM-dd'T'HH:mm:ss"
            )
          : null,
      marketingDate:
        getValues("marketingDate") === null
          ? null
          : format(
              new Date(getValues("marketingDate")),
              "yyyy-MM-dd'T'HH:mm:ss"
            ),
      adverseEvents: getValues("adverseEvents"),
      state: getValues("state"),
      currentLocation: getValues("currentLocation")
    };

    if (selectedImage) {
      const imageFile = await blobUrlToFile(selectedImage);
      combinedData.image = imageFile;
    }

    callApiAsync(
      () => postSaveBiomedicalEquipment(combinedData),
      (data) => {
        setBiomedicalEquipmentId(data.biomedicalEquipmentId);
        dispatch(setBiomedicalId(data.biomedicalEquipmentId));

        setSeverityState("success");
        setToastMessage("Equipo biomédico guardado con éxito");
        getSelectsBiomedicalEquipment();
        setShowToast(true);

        navigate("/biomedicalManagementDashboard/manageDevice");
      }
    );
  };

  return (
    <div>
      <p className="createRist-title">Datos del Equipo Biomédico</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={8}>
            <p className="create-label">
              Nro. de Serie: <span className="red-text">*</span>
            </p>
            <Grid container spacing={2} item xs={12} md={12}>
              <Grid item xs={8}>
                <FormTextField
                  name="serieText"
                  control={control}
                  formLabel=""
                  label=""
                  disabled
                />
              </Grid>
              <Grid item xs={4} className="VContainer align-end">
                {serieIdSelected && serieIdSelected !== 0 ? (
                  <></>
                ) : (
                  <LaddaButton
                    height={"42px"}
                    width={"42px"}
                    margin={"0 5px"}
                    variant="contained"
                    type="submit"
                    onClick={() => setOpenAddSerieNumberModal(true)}
                  >
                    <img className="btn-icon" src={icons.plusIcon} alt="icon" />
                  </LaddaButton>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <div className="form-group">
              <p className="create-label m-0">Imagen del equipo biomédico</p>
              <ImageUploadButton
                onImageChange={handleImageChange}
                buttonText="Agregar Imagen"
                path={selectedImage}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <FormRadioButton
              name="status"
              control={control}
              label=""
              options={options}
              error={errors.status}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {statusValue && (
              <FormTextField
                name="statusText"
                control={control}
                formLabel=""
                label=""
                error={errors.statusText}
              />
            )}
          </Grid>
          {/* first row */}
          <Grid item xs={12} md={4}>
            <p className="create-label">
              Nombre del Equipo: <span className="red-text">*</span>
            </p>
            <FormTextField
              name="name"
              control={control}
              formLabel=""
              label=""
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <p className="create-label">
              Marca: <span className="red-text">*</span>
            </p>
            <SimpleSelect
              name="brand"
              control={control}
              options={brandOptions}
              error={errors.brand}
              hideLabel={true}
              onChange={onChangeBrand}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <p className="create-label">
              Modelo: <span className="red-text">*</span>
            </p>
            <FormTextField
              name="model"
              control={control}
              formLabel=""
              label=""
              error={errors.model}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <p className="create-label">
              Cantidad: <span className="red-text">*</span>
            </p>
            <FormTextField
              name="quantity"
              control={control}
              formLabel=""
              label=""
              error={errors.quantity}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <p className="create-label">
              Importador: <span className="red-text">*</span>
            </p>
            <SimpleSelect
              name="importer"
              control={control}
              options={importerOptions}
              error={errors.importer}
              hideLabel={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <p className="create-label">
              Fabricante: <span className="red-text">*</span>
            </p>
            <SimpleSelect
              name="producer"
              control={control}
              options={producerOptions}
              error={errors.producer}
              hideLabel={true}
            />
          </Grid>
          {/* third row */}
          <Grid item xs={12} md={4}>
            <p className="create-label">
              Clasificación por riesgo: <span className="red-text">*</span>
            </p>
            <SimpleSelect
              name="classificationRisk"
              control={control}
              options={classificationRiskOptions}
              error={errors.classificationRisk}
              hideLabel={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <p className="create-label">
              Fecha de Importación: <span className="red-text">*</span>
            </p>
            <FormCalendar
              name="importDate"
              control={control}
              width={"100%"}
              formLabel=""
              error={errors.importDate}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          {/* Accessories */}
          <Grid item xs={12}>
            <p className="create-label">Accesorios:</p>
            <div className="HContainer j-end">
              <LaddaButton
                width={"40px"}
                fontSize={"12px"}
                height={"40px"}
                variant="contained"
                margin={"0px 0px 0px 25px"}
                onClick={() => {
                  if (brandIdSelected) {
                    setOpenAddAccessoryModal(true);
                  } else {
                    setSeverityState("warn");
                    setToastMessage("Es necesario seleccionar una marca");
                    setShowToast(true);
                  }
                }}
              >
                <img className="btn-icon" src={icons.plusIcon} alt="icon" />
              </LaddaButton>
            </div>
            <div className="mt-15">
              <SimpleTable
                data={accessoryTable}
                columns={accesoriesColumns}
                scrollHeight={"300px"}
                text={"Accesorios Agregados"}
                buttons={[
                  {
                    type: "icon",
                    icon: icons.editYellow,
                    onClick: (data) => {
                      setSelectedRow(data);
                      setOpenAddAccessoryModal(true);
                    }
                  }
                ]}
              />
            </div>

            <Divider className="mv-25" />

            <p className="createRist-title">Instalado</p>
            <Grid
              container
              justifyContent="center"
              spacing={2}
              className="pv-device-form"
            >
              <Grid item xs={12} md={6}>
                <p className="create-label">Instalado:</p>
                <SimpleSelect
                  name="installed"
                  control={control}
                  options={yesNoSelect}
                  hideLabel={true}
                  error={errors.installed}
                  defaultValue={null}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {watch("installed") === 1 && (
                  <>
                    <p className="create-label">Fecha de Instalación:</p>
                    <FormCalendar
                      name="installationDate"
                      control={control}
                      width={"100%"}
                      formLabel=""
                      error={errors.installationDate}
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <p className="createRist-title">Fecha de Comercialización</p>
            <Grid container spacing={2} className="pv-device-form">
              <Grid item xs={12} md={6}>
                <p className="create-label">Fecha de Comercialización:</p>
                <FormCalendar
                  name="marketingDate"
                  control={control}
                  width={"100%"}
                  formLabel=""
                  error={errors.marketingDate}
                />
              </Grid>
            </Grid>
            <p className="createRist-title">Sucesos de Seguridad</p>
            <Grid container spacing={2} className="pv-device-form">
              <Grid item xs={12} md={12}>
                <p className="create-label">Eventos adversos presentados:</p>
                <AdverseEventsTable
                  adverseEventsDataTable={adverseEventsDataTable}
                  setAdverseEventsDataTable={setAdverseEventsDataTable}
                />
                {/* <SimpleSelect
                  name="adverseEvents"
                  control={control}
                  options={adverseEventsSelect}
                  hideLabel={true}
                  error={errors.adverseEvents}
                /> */}
              </Grid>
            </Grid>
            <p className="createRist-title">Modalidad</p>
            <Grid container spacing={2} className="pv-device-form">
              <Grid item xs={12} md={6}>
                <p className="create-label">Estado:</p>
                <SimpleSelect
                  name="state"
                  control={control}
                  options={technologyModalityOption}
                  hideLabel={true}
                  error={errors.state}
                />
              </Grid>
            </Grid>
            <p className="createRist-title">Ubicación Actual</p>
            <p className="create-label">Ubicación Actual:</p>
            <Grid container spacing={2} className="pv-device-form">
              <Grid item xs={9} md={10}>
                <SimpleSelect
                  name="currentLocation"
                  control={control}
                  options={currentLocationSelect}
                  hideLabel={true}
                  error={errors.currentLocation}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <LaddaButton
                  height={"40px"}
                  width={"40px"}
                  margin={"0 5px"}
                  variant="contained"
                  type="submit"
                  onClick={() => setOpenAddUbication(true)}
                >
                  <img className="btn-icon" src={icons.plusIcon} alt="icon" />
                </LaddaButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className="j-center white-space">
          <LaddaButton
            width={"160px"}
            fontSize={"12px"}
            height={"40px"}
            variant="contained"
            type="submit"
            disabled={!isValid}
          >
            <p className="button_text">Guardar</p>
          </LaddaButton>
        </div>
      </form>
      <SelectSerieNumber
        open={openSelectSerieNumberModal}
        handleClose={hadleModalClose}
        setSerieIdSelected={setSerieIdSelected}
        setSerieSelected={setSerieSelected}
      />
      <AddSerieNumber
        open={openAddSerieNumberModal}
        close={hadleModalCloseAdd}
        setSerieIdSelected={setSerieIdSelected}
        setSerieSelected={setSerieSelected}
      />
      <AddAccessoryBase
        open={openAddAccessoryModal}
        close={hadleCloseAddAccessory}
        brandIdSelected={brandIdSelected}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        setAccessoryTable={setAccessoryTable}
        accessoryTable={accessoryTable}
      />
      <AddUbicationModal
        open={openAddUbication}
        close={() => setOpenAddUbication(false)}
        getSelectsBiomedicalData={getSelectsBiomedicalData}
      />
      <ToastNotification
        severity={severityState}
        summary={toastMessage}
        detail=""
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </div>
  );
};

export default BiomedicalEquipmentBase;
