import { Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import "./styles.css";
import { callApiAsync, getHeaderBanner } from "../../services/apiService";
import { formatWithoutHour } from "../../services/dateService";

const HeaderBanner = ({ type }) => {
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isMediumScreen = useMediaQuery(
    "(max-width:1200px) and (min-width:900px)"
  );
  const [headerBannerData, setHeaderBannerData] = useState(true);

  const getStrategiesDofaBannerData = async (id) => {
    await callApiAsync(
      () => getHeaderBanner(type),
      (response) => {
        setHeaderBannerData(response);
      }
    );
  };

  useEffect(() => {
    getStrategiesDofaBannerData();
  }, []);

  const formatdate = formatWithoutHour(headerBannerData.date);

  return (
    <div className="HContainer managmentBanner align-center">
      <div>
        <img className="banner-img" src={images.bannerLogo} alt="icon" />
      </div>
      <div className="dataContainer">
        <div className="bannerText-title-container">
          <p className="bannerText-title">{headerBannerData.title}</p>

          <p className="bannerText-title">{headerBannerData.sub_title}</p>
        </div>
        <div>
          <Grid container rowSpacing={2}>
            <Grid
              className="managmentBannerItem"
              item
              xs={12}
              md={isLargeScreen ? 2.9 : isMediumScreen ? 5.8 : 12}
            >
              <p className="bannerText">
                <b>Código:</b>  {headerBannerData.code}
              </p>
            </Grid>
            <Grid
              className="managmentBannerItem"
              item
              xs={12}
              md={isLargeScreen ? 2.9 : isMediumScreen ? 5.8 : 12}
            >
              <p className="bannerText">
                <b>Versión:</b>  {headerBannerData.version}
              </p>
            </Grid>
            <Grid
              className="managmentBannerItem"
              item
              xs={12}
              md={isLargeScreen ? 2.9 : isMediumScreen ? 5.8 : 12}
            >
              <p className="bannerText">
                <b>Fecha:</b>  {formatdate}
              </p>
            </Grid>
            <Grid
              className="managmentBannerItem"
              item
              xs={12}
              md={isLargeScreen ? 2.9 : isMediumScreen ? 5.8 : 12}
            >
              <p className="bannerText">
                <b>Página:</b>  {headerBannerData.page}
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
