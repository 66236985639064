import { headerBannerState, headerBannerType } from "../../../constants/enums";

export const bannerAdminColumns = [
  {
    field: "module",
    header: "Módulo",
    columnType: "custom",
    render: (rowData) => {
      let moduleName = "Módulo desconocido";

      switch (rowData.id) {
        case headerBannerType.dofa:
          moduleName = "DOFA";
          break;
        case headerBannerType.riskAndOportunity:
          moduleName = "Riesgos y Oportunidades";
          break;
      }

      return <p className="table-default-column">{moduleName}</p>;
    }
  },
  {
    field: "state",
    header: "Estatus",
    columnType: "custom",
    render: (rowData) => (
      <p className="table-default-column">
        {rowData.state === headerBannerState.completed
          ? "Completada"
          : "Pendiente"}
      </p>
    )
  },
  { field: "actionButtons", header: "", columnType: "actionButtons" }
];
