import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const formatTextWithLineBreaks = (text) => {
  if (!text) return "";
  return text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

export const trasformStringToArray = (string, separator) => {
  const resultArray = string.split(separator);
  const trimmedArray = resultArray.map((line) => line.trim());

  return trimmedArray;
};



 const useIsAllowedRoute = (allowedRoutes = []) => {
  const location = useLocation();
  return allowedRoutes.includes(location.pathname);
};

export const usePageChangeEffect = (callback) => {
  const location = useLocation();

  useEffect(() => {
    callback(); 
  }, [location.pathname]); 
};