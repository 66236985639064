import { riskLevelState } from "./enums";

export const months = [
  { name: "Enero", value: 1 },
  { name: "Febrero", value: 2 },
  { name: "Marzo", value: 3 },
  { name: "Abril", value: 4 },
  { name: "Mayo", value: 5 },
  { name: "Junio", value: 6 },
  { name: "Julio", value: 7 },
  { name: "Agosto", value: 8 },
  { name: "Septiembre", value: 9 },
  { name: "Octubre", value: 10 },
  { name: "Noviembre", value: 11 },
  { name: "Diciembre", value: 12 }
];

export const years = [
  { name: "2024", value: 2024 },
  { name: "2023", value: 2023 },
  { name: "2022", value: 2022 },
  { name: "2021", value: 2021 },
  { name: "2020", value: 2020 },
  { name: "2019", value: 2019 },
  { name: "2018", value: 2018 },
  { name: "2017", value: 2017 },
  { name: "2016", value: 2016 },
  { name: "2015", value: 2015 },
  { name: "2014", value: 2014 },
  { name: "2013", value: 2013 },
  { name: "2012", value: 2012 },
  { name: "2011", value: 2011 },
  { name: "2010", value: 2010 }
];

export const position = [
  { name: "Todos", value: 0 },
  { name: "Gerente", value: 1 },
  { name: "Contador", value: 2 },
  { name: "Vendedor", value: 3 },
  { name: "Promotor", value: 4 },
  { name: "Axuliar contable", value: 5 }
];

export const stateDofa = [
  { name: "Activo", value: 1 },
  { name: "Inactivo", value: 2 }
];

export const typeDofa = [
  { name: "Debilidades", value: 1 },
  { name: "Oportunidades", value: 2 },
  { name: "Fortalezas", value: 3 },
  { name: "Amenazas", value: 4 }
];

export const impactData = [
  // { name: "Insignificante", value: 1 },
  { name: "Menor", value: 2 },
  { name: "Moderado", value: 3 },
  { name: "Mayor", value: 4 }
  // { name: "Catastrófico", value: 5 },
];

export const probabilityData = [
  { name: "Raro (puede ocurrir excepcionalmente)", value: 1 },
  { name: "Improbable (puede ocurrir ocasionalmente)", value: 2 },
  { name: "Posible (puede ocurrir en cualquier momento del futuro)", value: 3 },
  { name: "Probable (probablemente va a ocurrir)", value: 4 },
  {
    name: "Casi seguro (ocurre en la mayoría de las circunstancias)",
    value: 5
  }
];

export const controlType = [
  { name: "PREVENTIVO", value: 4 },
  {
    name: "CORRECTIVO",
    value: 3
  },
  {
    name: "DETECTIVO",
    value: 2
  },
  {
    name: "INEXISTENTE",
    value: 1
  }
];

export const controlFrequency = [
  { name: "PERMANENTE", value: 3 },
  {
    name: "PERIODICO",
    value: 2
  },
  {
    name: "OCASIONAL",
    value: 1
  }
];

export const treatmentAction = [
  { name: "Evitar", value: 1 },
  {
    name: "Prevenir",
    value: 2
  },
  {
    name: "Proteger o mitigar",
    value: 3
  },
  {
    name: "Aceptar",
    value: 4
  },
  {
    name: "Retener",
    value: 5
  },
  {
    name: "Transferir o compartir",
    value: 6
  },
  {
    name: "Asumir",
    value: 7
  }
];

export const actionState = [
  { name: "", value: 0 },
  { name: "Abierta", value: 1 },
  {
    name: "Cerrada",
    value: 2
  }
];

export const riskStatus = [
  { name: "Materializado", value: riskLevelState.materialized },
  {
    name: "Cerrado sin Mitigación",
    value: riskLevelState.closedWithoutMitigation
  },
  {
    name: "Aceptado",
    value: riskLevelState.accepted
  },
  {
    name: "Mitigado",
    value: riskLevelState.mitigated
  },
  {
    name: "Mejorado",
    value: riskLevelState.improved
  },
  {
    name: "Latente",
    value: riskLevelState.latent
  }
];

export const YesNoOptions = [
  { label: "Sí", value: true },
  { label: "No", value: false }
];

export const YesNoNAOptions = [
  { label: "Sí", value: 1 },
  { label: "No", value: 2 },
  { label: "N/A", value: 3 }
];

export const fourMonthPeriod = [
  { name: "Primer Cuatrimestre", value: 1 },
  { name: "Segundo Cuatrimestre", value: 2 },
  { name: "Tercer Cuatrimestre", value: 3 }
];

export const opportunityState = [
  { name: "Creado", value: 1 },
  {
    name: "Activo",
    value: 2
  },
  {
    name: "Mejorado",
    value: 3
  }
];

export const technologyModalityOption = [
  { value: 1, name: "Apoyo tecnológico" },
  { value: 2, name: "Venta" },
  { value: 3, name: "Demostración" },
  { value: 4, name: "Backup" },
  { value: 5, name: "Asignado" },
  { value: 6, name: "Disponible" }
];
